import React from 'react';
import * as yup from 'yup';

import { Box } from 'shared/components/display';
import { StepFieldProps } from 'shared/components/forms/MultiStepForm/interfaces';
import ButtonGroupInput, { Option } from 'shared/components/molecules/ButtonGroupInput/ButtonGroupInput';
import { buttonGroupRequiredSchema } from 'shared/components/molecules/ButtonGroupInput/validation';

import styles from './styles';

export const genderOtherOption = { value: 'other', label: 'Other' };

const options = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
  { value: 'non-binary', label: 'Non-binary' },
  genderOtherOption,
];

export const GenderFormFields: React.FC<StepFieldProps<GenderValues>> = ({ formikProps }) => {
  return (
    <Box css={styles}>
      <ButtonGroupInput
        name={GENDER_FIELDNAMES.GENDER}
        otherOptionValue={genderOtherOption.value}
        otherOptionFieldName={GENDER_FIELDNAMES.GENDER_OTHER}
        className="gender__options"
      />
    </Box>
  );
};

export const GENDER_FIELDNAMES = {
  GENDER: 'gender',
  GENDER_OTHER: 'genderOther',
};

export const genderInitialValues: GenderValues = {
  gender: options,
  genderOther: '',
};

export const genderSchema = yup.object({
  gender: buttonGroupRequiredSchema,
  genderOther: yup.string().when('gender', {
    is: (value: Option[]) => {
      const genderOption = value.find((op) => op.value === genderOtherOption.value);
      return genderOption?.selected;
    },
    then: yup.string().customRequiredString().onlyLettersAndSpaces(),
  }),
});

export type GenderValues = {
  gender: Option[];
  genderOther: string;
};
