const BASE = '/org/:organizationId/account/:accountId/briefs/';

export default {
  BRIEF_INDEX: BASE,

  NEW_BRIEF: BASE + 'new',

  VIEW_INDIVIDUAL_BRIEF: BASE + ':briefId',

  EDIT_BRIEF: BASE + ':briefId/edit',

  VIEW_SUBMISSION: BASE + ':briefId/submissions/:submissionId',

  BRIEF_APPROVAL_CHECKOUT_INVITE: BASE + ':briefId/invitation/checkout',

  BRIEF_APPROVAL_CHECKOUT_CONFIRMATION: BASE + ':briefId/checkout/confirmation',

  BRIEF_APPROVAL_CHECKOUT: BASE + ':briefId/checkout/:creatorId',

  BRIEF_CHECKOUT: BASE + ':briefId/checkout',

  BRIEF_PUBLISH_CONFIRMATION: BASE + ':briefId/confirmation',

  SUBMISSION_CHECKOUT: BASE + ':briefId/submissions/:submissionId/checkout',
};
