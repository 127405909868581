import React, { useMemo, useState } from 'react';
import cs from 'classnames';
import { isEqual } from 'lodash';
import { Handles, Rail, Slider, Ticks, Tracks } from 'react-compound-slider';
import { useDeepEffect } from 'shared/hooks/useDeepEffect';
import { Flex } from 'shared/components/display';
import Handle from './Handle';
import HandleWithValue from './HandleWithValue';
import SliderRail from './Rail';
import Tick from './Tick';
import Track from './Track';
import styles from './styles';

export enum RangeTheme {
  REGULAR = 'regular',
  MINI = 'mini',
}

type Props = {
  min: number;
  max: number;
  values?: number[];
  onChange: (range: number[]) => void;
  disabled?: boolean;
  step?: number;
  /**
   * 1: Handles can cross
   * 2: Handle cannot cross and are separated by a step
   * 3: Handle cannot cross and are pushable
   */
  mode?: 1 | 2 | 3;
  hideTicks?: boolean;
  theme?: RangeTheme;
  // for if the values need to be displayed in a certain format
  valueDisplayMap?: Record<number, string>;
};

const RangeSlider: React.FC<Props> = ({
  // step = 1, // Uncomment if the use-case for a variable step amount is needed.
  mode = 2,
  min = 0,
  max = 1000000,
  disabled = false,
  onChange,
  values = [0, 1],
  hideTicks = false,
  theme = 'regular',
  valueDisplayMap,
}) => {
  const [range, setRange] = useState<number[]>(values);

  useDeepEffect(() => {
    if (!isEqual(values, range)) onChange(range);
  }, [range]);

  useDeepEffect(() => {
    if (!isEqual(values, range)) {
      setRange(values);
    }
  }, [values]);

  const domain = useMemo((): [number, number] => [min, max], [min, max]);

  return (
    <Flex className={cs('slider', `slider--${theme}`)} css={styles}>
      <Slider
        mode={mode}
        step={1}
        domain={domain}
        className="slider__container"
        onChange={([minValue, maxValue]) => setRange([minValue, maxValue])}
        values={range}
      >
        <Rail>{({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}</Rail>
        <Handles>
          {({ handles, getHandleProps }) => (
            <>
              {handles.map((handle, i) =>
                theme === RangeTheme.REGULAR ? (
                  <HandleWithValue
                    key={handle.id}
                    handle={handle}
                    min={min}
                    max={max}
                    getHandleProps={getHandleProps}
                    disabled={disabled}
                    valueDisplayMap={valueDisplayMap}
                  />
                ) : (
                  <Handle
                    key={handle.id}
                    handle={handle}
                    min={min}
                    max={max}
                    getHandleProps={getHandleProps}
                    disabled={disabled}
                    position={i === 0 ? 'left' : 'right'}
                    valueDisplayMap={valueDisplayMap}
                  />
                ),
              )}
            </>
          )}
        </Handles>
        <Tracks left={false} right={false}>
          {({ tracks, getTrackProps }) => (
            <>
              {tracks.map(({ id, source, target }) => (
                <Track key={id} source={source} target={target} getTrackProps={getTrackProps} disabled={disabled} />
              ))}
            </>
          )}
        </Tracks>
        {!hideTicks && (
          <Ticks count={10}>
            {({ ticks }) => (
              <>
                {ticks.map((tick) => (
                  <Tick key={tick.id} tick={tick} count={ticks.length} />
                ))}
              </>
            )}
          </Ticks>
        )}
      </Slider>
    </Flex>
  );
};

export default RangeSlider;
