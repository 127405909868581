import { Box } from 'shared/components/display';
import FieldErrorMessage from 'shared/components/FieldErrorMessage';
import socialMediaHandleStyles from 'shared/components/SocialMediaHandleInput/socialMediaHandleStyles';
import { Input as InputType } from 'shared/lib/formik';
import Input from 'shared/styles/Input/Input';
import InputLabel from 'shared/styles/InputLabel/InputLabel';
import cs from 'classnames';
import React, { useState } from 'react';

type Props = {
  className?: string;
  disabled?: boolean;
  label: string;
  maxLength?: number;
  placeholder?: string;
  dataCy?: string;
};

const SocialMediaHandleInput: InputType<string, Props> = ({
  field: { name, value = '', onChange, onBlur },
  form: { touched, errors },
  disabled = false,
  label,
  maxLength,
  placeholder,
  className = '',
  dataCy = '',
}) => {
  const [active, setActive] = useState(false);

  return (
    <Box dataCy={dataCy} css={socialMediaHandleStyles} className={cs(className, 'sm-handle')}>
      <InputLabel
        htmlFor={name}
        disabled={disabled}
        active={active}
        hasValue={value?.length > 0}
        isInvalid={touched[name] && !!errors[name]}
        className="sm-handle__label"
        dataCy={`${dataCy}__label`}
      >
        {label}
      </InputLabel>
      <span className="sm-handle__at">@</span>
      <Input
        placeholder={placeholder}
        value={value}
        type="text"
        maxLength={maxLength}
        disabled={disabled}
        onChange={onChange}
        onFocus={() => setActive(true)}
        onBlur={(e) => {
          e.persist();
          onBlur(e);
          setActive(false);
        }}
        name={name}
        id="sm-handle__input"
        dataCy={`${dataCy}__input`}
      />
      {Object.keys(errors)?.length > 0 ? <FieldErrorMessage dataCy={`${dataCy}__error`} name={name} /> : <></>}
    </Box>
  );
};

export default SocialMediaHandleInput;
