export const BRIEF_COPY = {
  // Button text
  BUTTON_PUBLISH: 'Publish Brief Now',
  BUTTON_CLOSE: 'Close Brief',
  BUTTON_REOPEN: 'Reopen Brief',
  BUTTON_CREATE_BRIEF: 'Create New Brief',
  BUTTON_DELETE_BRIEF: 'Delete Brief',
  BUTTON_DELETE_DRAFT: 'Delete Draft',
  BUTTON_VIEW_COLLECTION_BRIEF: 'Click to view full collection',
  BUTTON_APPLY_BRIEF: 'Apply',
  BUTTON_REAPPLY: 'Apply',
  BUTTON_ACCEPT_INVITE: 'Accept Invite',
  BUTTON_VIEW: 'View your submissions',
  BUTTON_BACK_TO_BRIEFS: 'View All Briefs',
  BUTTON_BACK_TO_BRIEF: 'Back to Brief',
  BUTTON_CHECKOUT_BRIEF: 'Confirm & Publish Brief',
  BUTTON_CONFIRM_CREATOR_APPROVAL: 'Confirm Creator Approval',
  BUTTON_CONNECT_IG: 'Connect Instagram to Apply',
  BUTTON_CONNECT_STRIPE: 'Connect Stripe to Submit',
  BUTTON_SAVE_BRIEF_DRAFT: 'Save Draft',
  BUTTON_SELECT_TEMPLATE: 'Select Template',
  BUTTON_SUBMIT_BRIEF: 'Start Submission',
  BUTTON_CANCEL_EDIT_BRIEF: 'Cancel',
  BUTTON_EDIT_BRIEF: 'Edit Brief',
  BUTTON_EDIT_DRAFT: 'Edit Draft',
  BUTTON_DUPLICATE: 'Duplicate Brief',
  BUTTON_DUPLICATE_DRAFT: 'Duplicate Draft',
  BUTTON_PAUSE: 'Mark as Full',
  BUTTON_RESUME: 'Resume Brief',
  BUTTON_REMOVE_SCENE: 'Delete',
  BUTTON_EDIT_SCENE: 'Edit',
  BUTTON_ADD_SCENE: '+ Add Scene',
  BUTTON_COMPLETE_BRIEF_SUBMISSION: 'Finish Submission',
  BUTTON_EDIT_BRIEF_TEMPLATE: 'Publish Brief Template',
  BUTTON_MARK_FULL_PROMPT_TITLE: 'Reject all pending creators?',
  BUTTON_MARK_FULL_PROMPT_MESSAGE:
    'These creators will see they were not selected for this brief. You can always approve a rejected creator if you change your mind.',
  BUTTON_VIEW_FULL_PROFILE: 'View Full Profile',

  // Modals
  MODAL_CLOSE_UNSEEN_SUBMISSIONS:
    "There are submissions that haven't been viewed yet. \nAre you sure you want to close the brief?",
  MODAL_CLOSE:
    'Are you sure you want to close the brief? \nApproved creators will not be able to create/edit submissions once it has been closed.',
  MODAL_REOPEN:
    'Are you sure you want to reopen the brief? \nApproved creators will be able to create/edit submissions once it has been reopened.',
  MODAL_EDIT_BRIEF:
    'Please confirm that you want to edit this brief. \nEditing a brief will notify approved creators that changes have been made.',
  MODAL_DELETE_BRIEF_DRAFT: 'Are you sure you want to delete this brief draft?',
  MODAL_CONFIRM_SHIPPING_SUBHEADING:
    "We'll ship you a product if you're approved for this brief \nIs this your current shipping address?",
  MODAL_CREATOR_BRIEF_LEGAL: `In order to respond to this Brief in a contractually compliant manner and receive a Creator Fee for your Content, you must comply with each and every aspect of this Brief.\n\nBy clicking confirm:\n\n<ul><li>You acknowledge that this brief requires you to <b>shoot</b>, <b>edit content</b>, and <b>submit to all</b> modules outlined in the Brief.</li><li>You declare you have reviewed the Brief Terms and Brand Guidelines, and your Content is compliant with all terms and conditions.</li><li>You acknowledge that Creator Content that does not comply with the Brand Guidelines and/or Brief Terms may require material revisions at your own expense or may be entirely rejected by a Brand for non-compliance.</li></ul>`,
  MODAL_CREATOR_BRIEF_LEGAL_AUTO_APPROVAL: `Congratulations! You have been invited to work on this brief.\n\nBy clicking confirm:\n\n<ul><li>You are committing to work on this brief.</li><li>You acknowledge that this brief requires you to <b>shoot</b>, <b>edit content</b>, and <b>submit to all</b> modules outlined in the Brief.</li><li>You declare you have reviewed the Brief Terms and Brand Guidelines, and your Content is compliant with all terms and conditions.</li><li>You acknowledge that Creator Content that does not comply with the Brand Guidelines and/or Brief Terms may require material revisions at your own expense or may be entirely rejected by a Brand for non-compliance.</li></ul>`,
  MODAL_APPLICATION_PITCH_DESCRIPTION:
    'Please also feel free to provide a short pitch to the brand on why you are a great fit to work on this brief.',
  MODAL_INSTORE_REQUIREMENT:
    'This brief requires you to be able to travel to at least one of these states for in-store creatives.',

  // Placeholders
  PLACEHOLDER_BRIEFS: 'Get started and create a new brief today',
  PLACEHOLDER_DESCRIPTION_BRIEF: 'No description',
  PLACEHOLDER_MODULE_FORM: 'Enter details. For more guidance, click the information icon.',
  PLACEHOLDER_AUTO_RESPONSE: 'No auto-response',
  PLACEHOLDER_NONE_SELECTED_BRIEF: 'No Brief Selected',
  PLACEHOLDER_NO_PRODUCT: 'Not specified',
  PLACEHOLDER_NO_PAYOUT: 'Not specified',
  PLACEHOLDER_NO_BRIEFS: 'No briefs to display',

  // Labels
  LABEL_ACCOUNT: 'Account',
  LABEL_APPROVAL_PENDING: 'Approval Pending',
  LABEL_APPROVED: 'Approved',
  LABEL_AUTO_RESPONSE: 'Auto-response to Applications',
  LABEL_CLOSED: 'Closed',
  LABEL_CREATOR_PAYOUT: 'Creator Payout',
  LABEL_ELITE_CREATOR_PAYOUT: 'Elite Creator Payout',
  LABEL_DATE_UPDATED: 'Date Updated',
  LABEL_NOT_SELECTED: 'Not Selected',
  LABEL_DESCRIPTION_BRIEF: 'Description',
  LABEL_FREE_PRODUCT: 'Product',
  LABEL_MODULES: 'Modules',
  LABEL_NAME_MODULE: 'Module Name',
  LABEL_DESCRIPTION_MODULE: 'Module Description',
  LABEL_NAME_BRIEF: 'Name',
  LABEL_PENDING: 'Your brief will be published soon',
  LABEL_PROMO_CODE: 'Promotional Code',
  LABEL_SUBMISSION_COMPLETED: 'Submission completed',
  LABEL_SUBMITTED: 'Submitted',
  LABEL_VIEWABLE_UPON_APPROVAL: 'Viewable upon brief approval',
  LABEL_WEBSITE: 'Product/Service',
  LABEL_COPY_OF_BRIEF: 'Copy of {briefName}',
  LABEL_DEADLINE: 'Deadline',
  LABEL_FULL: 'Full',
  LABEL_FULL_DISPLAY: 'All creator spots have been filled',
  LABEL_INVITE_ONLY: 'By Invitation Only',
  LABEL_SHIPPING_REQUIRED: 'Shipping',
  LABEL_BRAND_BADGE: 'Only show creators with brand badge',
  LABEL_BRIEF_CREATOR_FILTER: '{status} Creators',
  LABEL_CREATOR_APPROVAL_PRICING: 'Creator Approval Pricing',
  LABEL_ELITE_CREATOR_APPROVAL_PRICING: 'Elite Creator Approval Pricing',
  LABEL_CONTENT_GUIDELINES: 'Content Guidelines',
  LABEL_DOS_AND_DONTS: 'Do’s & Don’ts',
  LABEL_VALUE_PROPOSITIONS: 'Value Propositions',
  LABEL_CREATOR_REQUIREMENTS: 'Creator Requirements',

  // Links
  LINK_STRIPE_NOT_CONNECTED: 'Connect Stripe to submit',
  LINK_HOVER_STRIPE_NOT_CONNECTED: 'View in Account Settings',
  LINK_INSTAGRAM_NOT_CONNECTED: 'Connect Instagram to apply',
  LINK_HOVER_INSTAGRAM_NOT_CONNECTED: 'View in Account Settings',

  // Prompt
  PROMPT_SAVE_BRIEF: 'Do you want to save your brief?',
  PROMPT_SAVE_OPEN_BRIEF:
    'Please confirm that you want to edit this brief. \nEditing a brief will notify approved creators that changes have been made.',
  PROMPT_ACTIVE_BRIEF_LIMIT:
    'You have reached the maximum limit of 5 active briefs. Please finish your current briefs before applying to new ones. Thank you!',

  // Column Headers
  COLUMN_NAME_BRIEF: 'Brief',
  COLUMN_NUM_SUBMISSIONS: '# of Submissions',
  COLUMN_DATE_UPDATED: 'Date Updated',
  COLUMN_STATUS_BRIEF: 'Status',
  COLUMN_ACCOUNT: 'Brand',
  COLUMN_PAYOUT: 'Payout',

  // Warnings
  WARNING_NO_APPROVED_ASSETS: "You haven't approved any assets yet",
  WARNING_BRIEF_CLOSED: 'This brief is already closed. Please apply to another brief!',
  WARNING_FULL_BRIEF:
    "Thank you for applying, but we're unable to accept further applications, but all creator spots for this brief have been filled.\n Please refresh your page and make sure to apply to any other open briefs!",

  // headings
  HEADING_APPROVED_ASSETS: 'Approved Assets ({numAssets})',
  HEADING_APPROVE_CREATOR: 'Approve Creator',
  HEADING_BRIEF_ASSETS: 'Brief Assets',
  HEADING_CREATE_YOUR_OWN: 'Create Your Own',
  HEADING_DETAILS: 'Details',
  HEADING_SUBMISSION_CREATION_BRIEF: 'Brief: {name}',
  HEADING_PAYMENT_SUMMARY: 'Payment Summary',

  // Subheadings
  SUBHEADING_CREATE_YOUR_OWN: 'Start with a blank brief to suit your own marketing campaign',
  SUBHEADING_SCENE: 'Scene {num} ',

  DEFAULT_BRIEF_NAME: 'Unnamed brief',

  // forms
  FORM_HEADING_CONTENT_GUIDELINES: 'Content Guidelines',
  FORM_HEADING_DOS_AND_DONTS: 'Do’s & Don’ts',
  FORM_HEADING_VALUE_PROPOSITIONS: 'Value Propositions',
  FORM_HEADING_INTERNAL_NOTE: 'Internal Notes',
  FORM_HEADING_OVERVIEW: 'Brief Overview',
  FORM_INFO_OVERVIEW: `Provide a brief summary of the campaign’s goals and objectives. Key information to include:
  <ul>
    <li>Campaign Purpose: What is the main goal of this campaign?</li>
    <li>Target Audience: Who are you trying to reach?</li>
    <li>Core Message: What key message do you want to communicate?</li>
  </ul>`,
  FORM_HEADING_MODULE: 'Modules',
  FORM_INFO_MODULE_NAME: `Provide a name for the module that reflects the main theme of the video. Each module represents a unique theme for their video/imagery that creators must follow when submitting their content. e.g. Get Ready With Me Date Night Edition`,
  FORM_INFO_MODULE_DESCRIPTION: `Summarize the overall module for this content. What unique elements will set this content apart and engage the audience?Be descriptive but concise: What key themes should this concept highlight? Consider the viewer experiences, or messages you want to convey.`,
  FORM_HEADING_FILE_TYPE: 'File Type',
  FORM_HEADING_DURATION: 'Duration (in seconds)',
  FORM_TOOLTIP_MODULE: `BRKFST allows you to break your creative brief down by segments, so that the creators can produce specific videos for each segment. You may want to specify what type of lighting is required at the beginning of the video, what location is needed as background towards the end, or whether or not you want a product shot in the middle etc. You can do this by using the modules in the brief. You can add as many modules as you like. For example, if you are a pet food company, you may want one part of the video with dogs, another with cats and a third with just the box of treats.

    Once you receive the assets, you could combine the videos from the different modules to create longer videos and compositions or use the videos from specific modules directly to create the ads.

    Please remember to specify the duration of the videos required for each module (eg. 5 seconds, 10 seconds etc).`,
  FORM_HEADING_INVITE_ONLY: 'Invite to Apply Only',
  FORM_HEADING_AUTO_RESPONSE: 'Auto-Response to Approved Creators',
  FORM_HEADING_SCENES: 'Scene Breakdown',
  FORM_DESCRIPTION_SCENE_DESCRIPTION:
    'Describe the breakdown of the scene in detail. What actions should the creator take, and what visuals will best convey the intended message? e.g. Hook: Start by opening your front door and picking up the package from the porch.',
  FORM_DESCRIPTION_SCENE_NAME: 'Provide a name for the scene that captures its purpose .e.g. Hook',
  FORM_TOOLTIP_INVITE_ONLY:
    'To be considered for this brief, creators must be invited to apply. Without an invitation, they cannot submit an application.',
  FORM_TOOLTIP_AUTO_RESPONSE:
    'Once you approve any creator for your brief, this message will be sent to them automatically.',
  FORM_TOOLTIP_DURATION: 'Duration limit for assets in seconds.',
  FORM_NAME_HEADING: 'Brief Name',
  FORM_CREATOR_PAYOUT_HEADING: 'Creator Pricing',
  FORM_PRODUCT_INSTORE: 'In Store Visit US States',
  FORM_PRODUCT_HEADING: 'Product / Service',
  FORM_PLACEHOLDER_DESCRIPTION: 'Describe your brand, campaign objective, target audience, etc.',
  FORM_PLACEHOLDER_CONTENT_GUIDELINES: 'Enter details. For more guidance, click the information icon.',
  FORM_PLACEHOLDER_FREE_PRODUCT: 'e.g., Fashion, Perfume, Home Décor, Shoes etc.',
  FORM_PLACEHOLDER_WEBSITE: 'www.website.com/product',
  FORM_PLACEHOLDER_PROMO_CODE: 'Promo code to get your product/service (visible only to creators you approve)',
  FORM_PLACEHOLDER_MODULE_TAGS: "Tags/keywords identifying the content you're looking for",
  FORM_PLACEHOLDER_AUTO_RESPONSE: 'Enter details. For more guidance, click the information icon.',
  FORM_PLACEHOLDER_CREATOR_REQUIREMENTS: 'Enter details. For more guidance, click the information icon.',
  FORM_PLACEHOLDER_PAYOUT: 'Creator payout in USD',
  FORM_MODULE_DISPLAY: 'Module',
  FORM_MODULE_NAME_DISPLAY: 'Module {num}',
  FORM_PLACEHOLDER_INTERNAL_NOTE: 'This field is visible to the brkfst team only',
  FORM_PLACEHOLDER_BRIEF_GENERAL: 'Enter details. For more guidance, click the information icon.',
  FORM_INFO_CREATOR_REQUIREMENTS:
    'What qualities should the creator have? Outline the ideal creator profile. e.g. Age, Gender, Demographics',
  FORM_INFO_CONTENT_GUIDELINES: `What specific guidelines should creators follow? 

Please note that opportunities for reshooting or requesting revisions are limited based on guidelines that are not clearly outlined in the brief. 

Key information to include if applicable:
<ul>
  <li>
    <b> Tone and Style:</b> Define the overall vibe and mood of the content.
  </li>

  <li>
    <b> Aesthetic and Lighting:</b> Describe the visual elements such as
    lighting choices or home aesthetic, that contribute to the aesthetic.
  </li>

  <li>
    <b> Music, Voiceover, or On-Screen Text:</b> Outline audio and visual
    elements you’d like included such as music, voiceover, or on screen text.
  </li>
  <li>
    <b> Editing Style:</b> Note techniques such as the use of green screen or
    other effects you’d like featured.
  </li>
  <li>
    <b> Branding:</b> Specify the level of branding you want in the content,
    including details on assets such as logos, brand fonts, and other elements.
  </li>
  <li>
    <b> Creator Clothing:</b> Specify wardrobe choices that align with the tone
    and theme of your brand.
  </li>
</ul>
`,
  FORM_INFO_DOS_AND_DONTS: `What should creators definitely do, and what should they steer clear of?
 
 <ul>
  <li>e.g. <b> Dos:</b> Use the following branded language…</li>
  <li>e.g. <b> Don'ts:</b> Mention or show other brands, including on garments of
    clothing.
  </li>
</ul>
`,
  FORM_INFO_VALUE_PROPOSITIONS: `Share your brands talking points or value propositions, such as reasons why customers choose your products, notes on quality, and other relevant details that help promote the brand or product.`,
  FORM_INFO_BRIEF_NAME: `Summarize the campaign focus and target audience.
  e.g. Affordable Fashion for Young Women, June 2024.`,
  FORM_BANNER_PRICING: 'This section is visible to the brkfst team only.',
  TAB_TITLE_BRIEF_ASSETS: 'Brief Assets',
  TAB_TITLE_BRAND_GUIDELINES: 'Brand Guidelines',
  TAB_TITLE_BRIEF: 'Brief',
  TAB_TITLE_CREATORS: 'Creators',
  TAB_TITLE_SUBMISSIONS: 'Submissions',

  // Checkout
  CHECKOUT_HEADING: 'Publish Brief',
  CHECKOUT_SUBHEADING: 'Brief Information',
  CHECKOUT_SUCCESS_HEADING: 'Your Brief Has Been Launched!',
  CHECKOUT_SUCCESS_BODY_BRIEF_APPROVAL: 'Your payment is successful.',
  CHECKOUT_SUCCESS_BODY_BRIEF: 'Your brief will now be public for creators to see.',
  CHECKOUT_SUCCESS_BUTTON_BRIEF: 'View Brief',
  CHECKOUT_CONFIRM_LEAVE_PROCESSING_BRIEF:
    'Are you sure you want to leave this page while we are processing the Creator Approval?',

  // validation
  VALIDATION_INCOMPLETE_FIELD: 'This field is required.',
  VALIDATION_DURATION: 'Please enter a max duration greater than the specified minimum',
  VALIDATION_DURATION_INTEGER: 'Please enter a whole number of seconds',
  VALIDATION_MIN_DURATION: `Please enter a duration greater than  {minDuration} seconds`,
  VALIDATION_MAX_DURATION: `Please enter a duration less than {maxDuration} seconds`,
  VALIDATION_SCENE_ERROR: 'Please make sure all fields are filled in correctly',
  VALIDATION_NAME: "Unsupported character '}'",
  VALIDATION_STATE_REQUIREMENT: 'At least one State must be selected',

  DETAIL_PERFORMANCE_PAYOUT: '{payout} {creativeOnly, select, false {+ % ad spend} other {}}',
  CREATOR_PAYOUT_TIP:
    'In addition to the fixed payout, you will be earning a percentage of the ad spend for as long as the brand uses your assets',
};
