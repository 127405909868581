import React, { useEffect, useState } from 'react';
import cs from 'classnames';

import { Box } from 'shared/components/display';
import {
  MultiStepConfirmationProps,
  MultiStepFormValues,
  StepFormProps,
  StepProps,
  StepWrapperProps,
} from 'shared/components/forms/MultiStepForm/interfaces';
import type StepForm from 'shared/components/forms/MultiStepForm/Steps/StepForm';

import { useLocalStorage } from 'shared/hooks/useLocalStorage';

interface Props {
  children: (React.ReactElement<StepFormProps, typeof StepForm> | false)[];
  className?: string;
  ConfirmationPage?: React.ReactElement<MultiStepConfirmationProps>;
  confirmationTitle?: string;
  localStorageKey?: string;
  onConfirm: (values: MultiStepFormValues, clearDataFromLocalStorage: () => void) => void;
  StepWrapper: React.FC<StepWrapperProps>;
  css?: any;
  extraStepWrapperProps?: Record<string, any>;
}

interface State {
  currentStep: number;
  latestStep: number;
  values: MultiStepFormValues;
  isActiveStepDirty: boolean;
}

const initialState: State = {
  currentStep: 1,
  latestStep: 1,
  values: {},
  isActiveStepDirty: false,
};

const MultiStepForm: React.FC<Props> = ({
  children,
  className,
  ConfirmationPage,
  confirmationTitle = '',
  localStorageKey = '',
  onConfirm,
  StepWrapper,
  css,
  extraStepWrapperProps = {},
}) => {
  const [state, setState] = useState<State>(initialState);

  const validChildren = React.Children.toArray(children).filter((child) => React.isValidElement<StepProps>(child));
  const totalSteps = validChildren.length;
  const hasConfirmationStep = Boolean(ConfirmationPage);

  // Used by Step component
  const setDirtyState = (isDirty: boolean) => {
    setState((prev) => ({
      ...prev,
      isActiveStepDirty: isDirty,
    }));
  };

  const {
    value: dataFromStorage,
    setVal: saveDataInLocalStorage,
    removeVal: clearDataFromLocalStorage,
  } = useLocalStorage<State>(localStorageKey, initialState);

  const saveStepData = (nextStep: number, values?: Record<string, any>, getNewState?: (state: State) => void) => {
    setState((prev) => {
      const newState: State = {
        // get any previous saved data for this step, or default to an empty object
        values: {
          ...prev.values,
          [state.currentStep]: values || prev.values[state.currentStep],
        },
        isActiveStepDirty: false,
        currentStep: nextStep,
        latestStep: nextStep > prev.latestStep ? nextStep : prev.latestStep,
      };
      if (localStorageKey) saveDataInLocalStorage(newState);
      // for access to new state in final step
      if (getNewState) getNewState(newState);
      return newState;
    });
  };

  useEffect(() => {
    if (localStorageKey && dataFromStorage) setState(dataFromStorage);
  }, [dataFromStorage, localStorageKey]);

  return (
    <Box css={css} className={cs(className, 'multi-step-form')}>
      {validChildren.map((Child, index) => {
        const key = index + 1;
        // if statement for typescript to know that Child is a valid element so can access props
        if (React.isValidElement<StepProps>(Child)) {
          const extraChildProps = {
            initialValues: state.values[key] || Child.props.initialValues,
            isActive: key === state.currentStep,
            isActiveStepDirty: state.isActiveStepDirty,
            isCompleted: key <= state.latestStep,
            isLatestStep: key === state.latestStep,
            onStepChange: () => saveStepData(key),
            onSkip: () => {
              saveStepData(key + 1, Child.props.initialValues);
              if (Child.props.onSkip) Child.props.onSkip();
            },
            onCancel: () => {
              setState(initialState);
              if (Child.props.onCancel) Child.props.onCancel();
            },
            onSubmit: async (values, formikHelpers, stripe, elements) => {
              const lastStepConfirmation = (s: State) => {
                // If no confirmation page, then confirm on last step
                if (!hasConfirmationStep && s.currentStep - 1 === totalSteps) {
                  onConfirm(s.values, clearDataFromLocalStorage);
                }
              };

              if (Child.props.onSubmit) {
                // any validation that needs to happen after submit
                const result = await Child.props.onSubmit(values, formikHelpers, stripe, elements);

                if (result && result.success) saveStepData(key + 1, result?.newValues, lastStepConfirmation);
              } else {
                saveStepData(key + 1, values, lastStepConfirmation);
              }
            },
            setDirtyState,
            stepKey: key,
            totalSteps,
          };

          return (
            <StepWrapper
              isActive={extraChildProps.isActive}
              isActiveStepDirty={extraChildProps.isActiveStepDirty}
              isCompleted={extraChildProps.isCompleted}
              isOptional={Child.props.isOptional}
              onStepChange={extraChildProps.onStepChange}
              stepKey={extraChildProps.stepKey}
              title={Child.props.title}
              titleInfo={Child.props.titleInfo}
              totalSteps={totalSteps}
              extraWrapperProps={Child.props.extraWrapperProps}
              onCancel={extraChildProps.onCancel}
              key={key}
              isLatestStep={extraChildProps.isLatestStep}
              {...extraStepWrapperProps}
            >
              {React.cloneElement(Child, extraChildProps)}
            </StepWrapper>
          );
        }
      })}
      {ConfirmationPage && (
        <StepWrapper
          isActive={state.currentStep === totalSteps + 1}
          isCompleted={state.latestStep === totalSteps + 1}
          onStepChange={() => saveStepData(totalSteps + 1)}
          stepKey={totalSteps + 1}
          key={totalSteps + 1}
          totalSteps={totalSteps}
          title={confirmationTitle}
          {...extraStepWrapperProps}
          isConfirmationStep
        >
          {React.cloneElement(ConfirmationPage, {
            values: state.values,
            onConfirm: () => {
              onConfirm(state.values, clearDataFromLocalStorage);
            },
          })}
        </StepWrapper>
      )}
    </Box>
  );
};

export default MultiStepForm;
