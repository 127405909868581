import React from 'react';
import { faDollarSign, faEnvelope, faMessage, faSuitcase, faUser } from '@fortawesome/pro-regular-svg-icons';
import { faFilm } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';
import { differenceInMonths } from 'date-fns';
import { parsePhoneNumber } from 'libphonenumber-js';

import { formatOnboardingInfo, getSelectedValues } from 'features/users/pages/Onboarding/CreatorOnboarding';
import { CREATOR_ONBOARDING_STEPS } from 'features/users/pages/Onboarding/enums';
import Checkbox from 'shared/components/atoms/Checkbox';
import ExternalLink from 'shared/components/atoms/ExternalLink/ExternalLink';
import { Box, Button, Flex, Text } from 'shared/components/display';
import { MultiStepConfirmationProps } from 'shared/components/forms/MultiStepForm/interfaces';
import CategoryTags from 'shared/components/molecules/CategoryTags';
import { DefinitionList, ListDetail } from 'shared/components/molecules/DefinitionList';
import { ADDRESS_FIELDNAMES } from 'shared/components/organisms/AddressFormFields/constants';

import { CREATOR_COPY } from 'shared/config/copy';
import COLORS from 'shared/styles/colors';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { BrkfstUser } from 'shared/typings/user';
import { CHILD_TAGS, TEENAGER_TAGS } from 'shared/utilities/tagUtility';

import { ethnicityOtherOption, formatDependentDOB } from '../CreatorOnboardingStepsFields';
import { languageOtherOption } from '../CreatorOnboardingStepsFields/LanguageFormFields';

import styles from './styles';

interface Props {
  user: BrkfstUser;
}

const parseTags = (tags: string[]): { category: string; value: string }[] => {
  return tags.map((tag) => {
    const [category, value] = tag.split(':');
    return { category, value };
  });
};

const groupByCategory = (tags: { category: string; value: string }[]): Record<string, { value: string }[]> => {
  return tags.reduce((acc, tag) => {
    acc[tag.category] = acc[tag.category] || [];
    acc[tag.category].push({ value: tag.value });
    return acc;
  }, {} as Record<string, { value: string }[]>);
};

const CreatorOnboardingConfirmation: React.FC<MultiStepConfirmationProps & Props> = ({
  values = {},
  user,
  onConfirm,
}) => {
  const addressData = values[CREATOR_ONBOARDING_STEPS.ADDRESS];
  const countryCode = addressData[ADDRESS_FIELDNAMES.COUNTRY_CODE];
  const phone = addressData[ADDRESS_FIELDNAMES.PHONE_NUMBER];
  const { portfolioLink, tiktokHandle, instagramHandle, birthdate, gender, height, notifications } =
    formatOnboardingInfo(values);

  const workPreferenceData = values[CREATOR_ONBOARDING_STEPS.WORK_PREFERENCES];
  const languageData = values[CREATOR_ONBOARDING_STEPS.LANGUAGE];
  const ethnicityData = values[CREATOR_ONBOARDING_STEPS.ETHNICITY];
  const filmingBackdropData = values[CREATOR_ONBOARDING_STEPS.LOCATION];
  const modelData = values[CREATOR_ONBOARDING_STEPS.MODEL];

  const ethnicityValues = getSelectedValues({
    data: ethnicityData?.ethnicity,
    prefix: 'ethnicity',
    otherPrefix: 'ethnicityOther',
    otherOption: ethnicityOtherOption,
    otherValue: ethnicityData?.ethnicityOther,
  });

  const languageValues = getSelectedValues({
    data: languageData?.language,
    prefix: 'language',
    otherPrefix: 'languageOther',
    otherOption: languageOtherOption,
    otherValue: languageData?.languageOther,
  });
  const modelValues = getSelectedValues({ data: modelData?.models, prefix: 'model' });
  const filmingBackdropValues = getSelectedValues({
    data: filmingBackdropData?.filmingBackdrop,
    prefix: 'filming backdrop',
  });
  const workTypeValues = getSelectedValues({ data: workPreferenceData.workType, prefix: 'work preference' });
  const editingValues = getSelectedValues({ data: workPreferenceData.editing, prefix: 'editing' });

  const allTags = [
    ...ethnicityValues,
    ...modelValues,
    ...filmingBackdropValues,
    ...workTypeValues,
    ...editingValues,
    ...languageValues,
  ];
  const tags = parseTags(allTags);
  const onboardingTags = groupByCategory(tags);
  const { ethnicity, language, ethnicityOther, languageOther } = onboardingTags;
  const showPortfolioSection = !!portfolioLink || !!tiktokHandle || !!instagramHandle;

  return (
    <Box css={styles} className={cs('confirmation')}>
      <Text variant={TEXT_VARIANTS.SUBHEADING} className="confirmation__subheading">
        {CREATOR_COPY.CAPTION_CONFIRM_DETAILS}
      </Text>
      <Text variant={TEXT_VARIANTS.BODY} className="confirmation__caption">
        {CREATOR_COPY.CAPTION_CONFIRMATION}
      </Text>
      <Text variant={TEXT_VARIANTS.H4} className="confirmation__name">
        {user.fullName}
      </Text>

      {showPortfolioSection && (
        <>
          <Text variant={TEXT_VARIANTS.H4} className="confirmation__section-title">
            <FontAwesomeIcon icon={faFilm} color={COLORS.NEUTRAL500} className="confirmation__icon" size="lg" />{' '}
            Portfolio
          </Text>
          <DefinitionList className="confirmation__section">
            <ListDetail text={CREATOR_COPY.LABEL_PORTFOLIO_LINK} hide={!portfolioLink} variant={TEXT_VARIANTS.CATEGORY}>
              <ExternalLink href={portfolioLink || ''} />
            </ListDetail>
            <ListDetail text={CREATOR_COPY.LABEL_IG} variant={TEXT_VARIANTS.CATEGORY} hide={!instagramHandle}>
              {instagramHandle || ''}
            </ListDetail>
            <ListDetail text="TikTok" hide={!tiktokHandle} variant={TEXT_VARIANTS.CATEGORY}>
              {tiktokHandle || ''}
            </ListDetail>
          </DefinitionList>
        </>
      )}
      <Text variant={TEXT_VARIANTS.H4} className="confirmation__section-title">
        <FontAwesomeIcon icon={faEnvelope} color={COLORS.NEUTRAL500} className="confirmation__icon" size="lg" /> Contact
        Info
      </Text>
      <DefinitionList className="confirmation__section">
        <ListDetail text={CREATOR_COPY.LABEL_CREATOR_EMAIL} variant={TEXT_VARIANTS.CATEGORY}>
          {user.email}
        </ListDetail>
        <ListDetail
          text={CREATOR_COPY.LABEL_CREATOR_PHONE}
          hide={!phone}
          variant={TEXT_VARIANTS.CATEGORY}
          detailsClassName="confirmation__phone"
        >
          {parsePhoneNumber(phone, countryCode.toUpperCase()).formatInternational()}
        </ListDetail>
        <ListDetail text={CREATOR_COPY.LABEL_MAILING_ADDRESS} hide={!addressData} variant={TEXT_VARIANTS.CATEGORY}>
          {addressData[ADDRESS_FIELDNAMES.ADDRESS_LINE_1]}
          {addressData[ADDRESS_FIELDNAMES.ADDRESS_LINE_2] && `, ${addressData[ADDRESS_FIELDNAMES.ADDRESS_LINE_2]}`}
          {`, ${addressData[ADDRESS_FIELDNAMES.CITY]}`}
          {`, ${addressData[ADDRESS_FIELDNAMES.STATE].label}`}
          {` ${addressData[ADDRESS_FIELDNAMES.POSTAL_CODE]}`}
          {` - ${addressData[ADDRESS_FIELDNAMES.COUNTRY].label}`}
        </ListDetail>
      </DefinitionList>

      <Text variant={TEXT_VARIANTS.H4} className="confirmation__section-title">
        <FontAwesomeIcon icon={faUser} color={COLORS.NEUTRAL500} className="confirmation__icon" size="lg" /> Profile
        Info
      </Text>
      <DefinitionList className="confirmation__section">
        <ListDetail text={CREATOR_COPY.LABEL_CREATOR_DOB} variant={TEXT_VARIANTS.CATEGORY}>
          {birthdate}
        </ListDetail>
        <ListDetail text={CREATOR_COPY.LABEL_CREATOR_GENDER} hide={!gender} variant={TEXT_VARIANTS.CATEGORY}>
          {gender?.toUpperCase() || ''}
        </ListDetail>
        <ListDetail text={CREATOR_COPY.LABEL_CREATOR_HEIGHT} hide={!height} variant={TEXT_VARIANTS.CATEGORY}>
          {height?.toUpperCase() || ''}
        </ListDetail>
      </DefinitionList>
      <Flex className="confirmation__tags-section">
        {ethnicity && (
          <CategoryTags category="ethnicity" theme="top-category" className="confirmation__tags" values={ethnicity} />
        )}
        {language && (
          <CategoryTags category="languages" theme="top-category" className="confirmation__tags" values={language} />
        )}
      </Flex>
      <DefinitionList className="confirmation__section">
        <ListDetail
          text={CREATOR_COPY.LABEL_OTHER_NOTE}
          hide={!ethnicityOther && !languageOther}
          variant={TEXT_VARIANTS.CATEGORY}
        >
          {ethnicityOther ? `Other ethnicity: ${ethnicityOther.map(({ value }) => value)}` : ''}
          {ethnicityOther && languageOther ? ', ' : ''}
          {languageOther ? `Other language: ${languageOther.map(({ value }) => value)}` : ''}
        </ListDetail>
      </DefinitionList>

      <Text variant={TEXT_VARIANTS.H4} className="confirmation__section-title">
        <FontAwesomeIcon icon={faSuitcase} color={COLORS.NEUTRAL500} className="confirmation__icon" size="lg" /> Work
        Info
      </Text>
      <Flex className="confirmation__tags-section">
        {(onboardingTags['work preference'] || onboardingTags.editing) && (
          <CategoryTags
            category="work preference"
            theme="top-category"
            className="confirmation__tags"
            values={[...onboardingTags['work preference'], ...onboardingTags.editing]}
          />
        )}
        {(modelData.models.length > 0 || modelData.children.length > 0 || modelData.teenagers.length > 0) && (
          <CategoryTags
            category="models"
            theme="top-category"
            className="confirmation__tags"
            duplicates
            values={[
              ...modelData.models
                .filter(({ selected, value }) => selected && ![...CHILD_TAGS, ...TEENAGER_TAGS].includes(value))
                .map((model) => ({
                  value: [...CHILD_TAGS, ...TEENAGER_TAGS].includes(model.value) ? model.label : model.value,
                })),
              ...(modelData.children?.map(({ dob }) => {
                if (!dob) return { value: 'child' };
                const ageInMonths = differenceInMonths(new Date(), new Date(formatDependentDOB(dob)));
                return `child (${ageInMonths < 12 ? `${ageInMonths} mo` : `${Math.floor(ageInMonths / 12)} yr`})`;
              }) || []),
              ...(modelData.teenagers?.map(({ dob }) => {
                if (!dob) return { value: 'teenager' };
                const ageInMonths = differenceInMonths(new Date(), new Date(formatDependentDOB(dob)));
                if (ageInMonths >= 12 * 13 && ageInMonths <= 12 * 18)
                  return `teenager (${Math.floor(ageInMonths / 12)} yr)`;
              }) || []),
            ]}
          />
        )}
        {onboardingTags['filming backdrop'] && (
          <CategoryTags
            category="filming backdrop"
            theme="top-category"
            className="confirmation__tags"
            values={onboardingTags['filming backdrop']}
          />
        )}
      </Flex>

      <Text variant={TEXT_VARIANTS.H4} className="confirmation__section-title">
        <FontAwesomeIcon icon={faDollarSign} color={COLORS.NEUTRAL500} className="confirmation__icon" size="lg" />
        Payment
      </Text>
      <DefinitionList className="confirmation__section">
        <ListDetail
          text={CREATOR_COPY.LABEL_CONNECT_STRIPE}
          detailsClassName="confirmation__stripe"
          variant={TEXT_VARIANTS.CATEGORY}
        >
          {!user.stripeAccountId ? 'Not Connected' : 'Connected'}
        </ListDetail>
      </DefinitionList>

      <Text variant={TEXT_VARIANTS.H4} className="confirmation__section-title">
        <FontAwesomeIcon icon={faMessage} color={COLORS.NEUTRAL500} className="confirmation__icon" size="lg" /> Text
        Notifications
      </Text>
      <Box className="confirmation__notifications">
        <Checkbox checked={notifications} disabled name="notifications" />
        <Text className="confirmation__notifications__status">{notifications ? 'On' : 'Off'}</Text>
      </Box>

      <Box className="confirmation__confirm-btn">
        <Button onClick={onConfirm}>Confirm</Button>
      </Box>
    </Box>
  );
};

export default CreatorOnboardingConfirmation;
