import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'rebass/styled-components';
import { faAngleDown, faAngleLeft, faAngleRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';

import { useAccounts } from 'features/accounts/hooks/useAccounts';
import Sidebar from 'features/home/components/atoms/Sidebar';
import brandSideBarStyles from 'features/home/components/organisms/BrandSideBar/brandSideBarStyles';
import PlatformSidebarNav from 'features/home/components/organisms/PlatformSidebarNav/PlatformSidebarNav';
import NavItem from 'shared/components/atoms/NavItem';
import SidebarNavBar from 'shared/components/atoms/SidebarNavBar';
import Skeleton from 'shared/components/atoms/Skeleton';
import { Box, Flex, Text } from 'shared/components/display';
import Dropdown from 'shared/components/molecules/Dropdown';

import { ACCOUNT_COPY } from 'shared/config/copy';
import { SIDEBAR } from 'shared/config/formatting';
import { PERMISSIONS_MAP } from 'shared/config/permissions';
import { LOCAL_STORAGE } from 'shared/config/storage';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { usePermissions } from 'shared/hooks/usePermissions';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { TEXT_VARIANTS } from 'shared/styles/text';
import theme from 'shared/styles/themes';
import { UserType } from 'shared/typings/user/enums';
import { LinkCreator, RouteKey } from 'shared/utilities/linkUtility';

const BrandSideBar = () => {
  const navigate = useNavigate();
  const params = useParams<{ organizationId: string; accountId: string }>();
  const organizationId = +params.organizationId;
  const accountId = +params.accountId;

  const { isAuthorized } = usePermissions();
  const { isSuperadmin } = useCurrentUser();
  const {
    account,
    accounts,
    loading: accountLoading,
  } = useAccounts({
    accountId: params.accountId,
  });

  const [expanded, setExpanded] = useState(() => {
    const initialExpanded = localStorage.getItem(LOCAL_STORAGE.SIDEBAR_EXPANDED);
    return initialExpanded !== 'false';
  });

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE.SIDEBAR_EXPANDED, `${expanded}`);
  }, [expanded]);

  const accountOnClick = (accountId: number) => () => {
    const link = LinkCreator.createLink({
      routeKey: 'ACCOUNT',
      variables: {
        organizationId,
        accountId,
      },
    });
    navigate(link);
  };

  const canCreateAccount = isAuthorized(organizationId, PERMISSIONS_MAP.ADD_ACCOUNT.API);

  const makeLink = (routeKey: RouteKey) =>
    LinkCreator.createLink({
      userType: UserType.ORG,
      routeKey,
      variables: {
        organizationId,
        accountId,
      },
    });

  return (
    <Flex
      css={brandSideBarStyles}
      className={cs('brand-side-bar', {
        'brand-side-bar--expanded': expanded,
      })}
    >
      <Sidebar expanded={expanded}>
        <button
          onClick={(e) => {
            setExpanded((prevExpanded) => !prevExpanded);
            // @ts-ignore
            e.target.blur();
          }}
          type="button"
          className="brand-side-bar__toggle-btn"
          data-cy="brand-side-bar__toggle-btn"
        >
          <FontAwesomeIcon icon={expanded ? faAngleLeft : faAngleRight} />
        </button>
        {accountLoading ? (
          <Box className="brand-side-bar__loading-wrapper">
            <Skeleton height={28} />
          </Box>
        ) : (
          <Dropdown
            ClickComponent={
              <Button variant={BUTTON_VARIANTS.TEXT_ONLY} className="brand-side-bar__account-btn">
                <Text variant={TEXT_VARIANTS.H6} className="endWithEllipsis">
                  {account.name}
                </Text>
                <FontAwesomeIcon icon={faAngleDown} />
              </Button>
            }
            placement="bottom-start"
            width={`calc(${SIDEBAR.EXPANDED_WIDTH} - ${theme.space[5]} - ${theme.space[5]})`}
            searchable
            Footer={
              canCreateAccount ? (
                <Button
                  onClick={() =>
                    navigate(
                      LinkCreator.createLink({
                        routeKey: 'CREATE_ACCOUNT',
                        variables: {
                          organizationId,
                        },
                      }),
                    )
                  }
                  variant={BUTTON_VARIANTS.DROPDOWN_FOOTER}
                >
                  {ACCOUNT_COPY.BUTTON_CREATE_ACCOUNT}
                </Button>
              ) : (
                <></>
              )
            }
          >
            {accounts.map((account) => (
              <Button
                className={cs({ highlighted: account.id === accountId })}
                key={account.name}
                onClick={accountOnClick(account.id)}
              >
                {account.name}
              </Button>
            ))}
          </Dropdown>
        )}
        <SidebarNavBar>
          <NavItem exact text="Overview" path={makeLink('ACCOUNT_ROUTES')} tabIndex={expanded ? 0 : -1} />
          <NavItem text="Briefs" path={makeLink('BRIEF_INDEX')} tabIndex={expanded ? 0 : -1} />
          <NavItem text="Creators" path={makeLink('ACCOUNT_CREATORS')} tabIndex={expanded ? 0 : -1} isNew />
          <NavItem text="Library" path={makeLink('ASSETS_LIBRARY')} tabIndex={expanded ? 0 : -1} />
          <NavItem
            text="Trends"
            path={makeLink('TRENDS_LIBRARY')}
            tabIndex={expanded ? 0 : -1}
            isNew
            dataCy="brand-side-bar__trends-nav-item"
          />
          <NavItem
            text="Settings"
            path={makeLink('EDIT_ACCOUNT_DETAILS')}
            hide={!isAuthorized(organizationId, [PERMISSIONS_MAP.EDIT_ACCOUNT.API, PERMISSIONS_MAP.DELETE_ACCOUNT.API])}
            tabIndex={expanded ? 0 : -1}
          />
          <NavItem text="Pricing" path={makeLink('PRICING')} tabIndex={expanded ? 0 : -1} hide={!isSuperadmin} />
        </SidebarNavBar>
        <hr className="brand-side-bar__divider" />
        <PlatformSidebarNav expanded={expanded} />
      </Sidebar>
    </Flex>
  );
};

export default BrandSideBar;
