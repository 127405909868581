import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';

export default css({
  '.onboarding-steps': {
    '&__select-multiple': {
      color: COLORS.NEUTRAL500,
      textTransform: 'none',
      marginTop: '1rem',
      marginBottom: 0,
    },
  },
  '.work-pref': {
    '&__caption': {
      color: COLORS.NEUTRAL500,
      textTransform: 'none',
      marginTop: '1rem',
      marginBottom: 0,
    },
    '&__subheading': {
      marginBottom: 0,
      marginTop: '1rem',
    },
  },
  '.models': {
    '&__caption': {
      color: COLORS.NEUTRAL500,
      textTransform: 'none',
      marginBottom: 0,
    },
    '&__subheading': {
      marginBottom: 0,
      marginTop: '1rem',
    },
    '&__footer': {
      marginTop: '1.5rem',
    },
    '&__note': {
      backgroundColor: COLORS.ORANGE100,
      display: 'flex',
      alignItems: 'center',
    },
    '&__note-icon': {
      padding: '1rem',
    },
    '&__note-text': {
      margin: '0',
      color: COLORS.ORANGE800,
    },
  },
  '.age': {
    '&__caption': {
      color: COLORS.NEUTRAL500,
      textTransform: 'none',
    },
  },
  '.gender': {
    '&__options': {
      marginTop: '1.5rem',
    },
  },
  '.height': {
    '&__select': {
      marginTop: '2rem',
    },
  },
  '.notifications': {
    '&__caption': {
      color: COLORS.NEUTRAL500,
      textTransform: 'none',
      marginTop: '1rem',
    },
    '&__toggle': {
      margin: '0 0 1rem 4px',
    },
    '&__note': {
      backgroundColor: COLORS.ORANGE100,
      display: 'flex',
      alignItems: 'center',
    },
    '&__note-icon': {
      padding: '1rem',
    },
    '&__note-text': {
      margin: '0',
      color: COLORS.ORANGE800,
    },
  },
});
