// * Lib
import React, { Fragment, Suspense, useRef } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { ErrorBoundary } from '@rollbar/react';
import ScrollToTop from 'app/ScrollToTop';

import BriefsBoard from 'features/briefs/pages/BriefsBoard';
import CreatorBriefs from 'features/briefs/pages/CreatorBriefs';
import RedirectCreator from 'features/creators/RedirectCreator';
import CreatorNavBar from 'features/home/components/organisms/CreatorNavBar';
import CreatorOnboardingNavBar from 'features/home/components/organisms/CreatorOnboardingNavBar';
import CreatorAssetsLibrary from 'features/media/pages/CreatorAssetsLibrary/CreatorAssetsLibrary';
import CreateUpdateSubmission from 'features/submissions/pages/CreateUpdateSubmission';
import { ErrorPage, NoAccessMsg } from 'features/ui/pages/ErrorPages';
import TestErrorBoundaryPage from 'features/ui/pages/TestErrorBoundaryPage/TestErrorBoundaryPage';
import Earnings from 'features/users/pages/Earnings/Earnings';
// * Display/UI
import { Box } from 'shared/components/display';
import RightDrawer from 'shared/components/layout/RightDrawer/RightDrawer';
import LazySpinner from 'shared/components/molecules/LazySpinner';

// * Util
import { LOCAL_STORAGE } from 'shared/config/storage';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import lazyWithRetry from 'shared/lib/lazyWithRetry';
import CreatorBriefRoutes from 'shared/router/creatorRoutes/CreatorBriefRoutes';
import CreatorUserRoutes from 'shared/router/creatorRoutes/CreatorUserRoutes';
import CurrentUserProfile from 'shared/router/creatorRoutes/CurrentUserProfile';
import { UserType } from 'shared/typings/user/enums';
import { LinkCreator } from 'shared/utilities/linkUtility';

// * Styles
import styles from './creatorRoutesStyles';
import RedirectToOnboarding from './RedirectToOnboarding';

const OnboardCompletePage = lazyWithRetry(
  () => import('features/users/pages/CreatorOnboardComplete/CreatorOnboardComplete'),
);
const CreatorOnboarding = lazyWithRetry(() => import('features/users/pages/Onboarding/CreatorOnboarding'));
const CreatorCollection = lazyWithRetry(() => import('features/media/pages/CreatorCollection/CreatorCollection'));
const MessageCenter = lazyWithRetry(() => import('features/messages/pages/MessageCenter/MessageCenter'));
const CreatorHome = lazyWithRetry(() => import('features/users/pages/CreatorHome/CreatorHome'));

const RoutesWrapper = () => {
  const contentRef = useRef(null);
  const location = useLocation<any>();

  return (
    <Fragment>
      <CreatorNavBar />
      <ScrollToTop contentRef={contentRef} />
      {/* @ts-ignore */}
      <ErrorBoundary key={location.pathname} fallbackUI={ErrorPage}>
        <Box id="content" ref={contentRef} css={styles}>
          <Box className="route-wrapper">
            <Suspense fallback={<LazySpinner />}>
              <Outlet />
            </Suspense>
          </Box>
          <RightDrawer />
        </Box>
      </ErrorBoundary>
    </Fragment>
  );
};

const CreatorRoutes = () => {
  const { currentUser } = useCurrentUser();

  return (
    <Routes>
      <Route path="/" element={<RoutesWrapper />}>
        {/* Redirect to redirectURI or Dashboard */}
        <Route index element={<RedirectCreator />} />
        <Route path="dashboard" element={<CreatorHome />} />

        <Route path="briefs-board" element={<BriefsBoard />} />
        <Route path="your-briefs" element={<CreatorBriefs />} />

        <Route path="briefs/:briefId/*" element={<CreatorBriefRoutes />} />
        <Route path="submissions/:submissionId" element={<CreateUpdateSubmission />} />

        <Route path="library">
          <Route index element={<CreatorAssetsLibrary />} />
          <Route path=":collectionId" element={<CreatorCollection />} />
        </Route>
        <Route path="messaging" element={<MessageCenter />} />
        <Route path="earnings" element={<Earnings />} />
        <Route path="onboarding" element={<OnboardCompletePage />} />

        <Route path="user">
          <Route index element={<CurrentUserProfile />} />
          <Route path=":userId/*" element={<CreatorUserRoutes />} />
        </Route>

        <Route
          path="settings"
          element={
            <Navigate
              to={LinkCreator.createLink({
                userType: UserType.CREATOR,
                routeKey: 'ACCOUNT_SETTINGS',
                variables: {
                  userId: currentUser.id,
                },
              })}
            />
          }
        />
        <Route path="no-access" element={<NoAccessMsg />} />
        <Route path="error" element={<TestErrorBoundaryPage />} />
        <Route path="*" element={<Navigate to="/not-found" />} />
      </Route>
    </Routes>
  );
};

const LOCAL_STORAGE_KEYS = [LOCAL_STORAGE.CREATOR_ONBOARDING];

export const CreatorOnboardingRoutes = () => {
  const contentRef = useRef(null);
  const location = useLocation<any>();

  return (
    <Fragment>
      <CreatorOnboardingNavBar localStorageKeys={LOCAL_STORAGE_KEYS} />
      <ScrollToTop contentRef={contentRef} />
      {/* @ts-ignore */}
      <ErrorBoundary key={location.pathname} fallbackUI={ErrorPage}>
        <Box id="content" ref={contentRef} css={styles}>
          <Box className="route-wrapper">
            <Suspense fallback={<LazySpinner />}>
              <Routes>
                <Route index element={<RedirectToOnboarding />} />
                <Route path="onboarding" element={<CreatorOnboarding />} />
                <Route path="*" element={<Navigate to="/not-found" />} />
              </Routes>
            </Suspense>
          </Box>
          <RightDrawer />
        </Box>
      </ErrorBoundary>
    </Fragment>
  );
};

export default CreatorRoutes;
