import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';
import { mediaquery } from 'shared/styles/styleFunctions';

export default css({
  '&.portfolio-form': {
    [mediaquery.md]: { paddingLeft: '8px' },
  },
  '.portfolio-form': {
    '&__label': {
      paddingTop: '10px',
      marginBottom: '0',
    },
    '&__optional': {
      paddingTop: '10px',
      marginBottom: '0',
      fontSize: '1rem',
      color: COLORS.NEUTRAL500,
    },
    '&__btns-wrapper': {
      width: '100%',
      justifyContent: 'end',
      paddingTop: '15px',
    },
    '&__cancel-btn': {
      width: '100px',
      marginRight: '10px',
      backgroundColor: COLORS.NEUTRAL200,
      color: COLORS.NEUTRAL700,
      cursor: 'pointer',
    },
  },
});
