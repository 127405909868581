import React from 'react';
import { faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field } from 'formik';
import shortid from 'shortid';

import {
  ModuleSceneValues,
  MAX_SCENES,
  MIN_SCENES,
  SCENE_FIELD_NAMES,
} from 'features/briefs/components/organisms/BriefMultiPageForm/config';
import { Box, Button, Flex, Text } from 'shared/components/display';
import Heading from 'shared/components/molecules/Heading';
import { RICHTEXT_DEFAULT_VALUE, RichtextInput } from 'shared/components/Richtext';
import TextInput from 'shared/components/TextInput';

import { BRIEF_COPY } from 'shared/config/copy';
import { makeArray } from 'shared/lib/formik';
import { intl } from 'shared/lib/intl';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import COLORS from 'shared/styles/colors';
import { TEXT_VARIANTS } from 'shared/styles/text';

import styles from './styles';

const moduleSceneDefaults: ModuleSceneValues = {
  title: '',
  description: RICHTEXT_DEFAULT_VALUE,
};
const createScene = () => ({ ...moduleSceneDefaults, formId: shortid.generate() });

interface Props {
  moduleIndex: number;
}

const ModuleScenesForm = makeArray<ModuleSceneValues, Props>(({ remove, push, values, name, moduleIndex }) => {
  const onPush = () => {
    push(createScene());
  };

  return (
    <Box css={styles} className="module-scenes-form" dataCy="module-scenes-form">
      <Heading text={BRIEF_COPY.FORM_HEADING_SCENES} className="module-scenes-form__section-header" />
      {values?.map((scene, idx) => (
        <Box key={`${moduleIndex}-${idx}`} className="module-scenes-form__scene-wrapper" dataCy="module-scene">
          <Flex className="module-scenes-form__header">
            <Text className="module-scenes-form__label" variant={TEXT_VARIANTS.SUBHEADING}>
              {intl.formatMessage({ id: 'SUBHEADING_SCENE' }, { num: idx + 1 })}
            </Text>
            <Button
              type="button"
              variant={BUTTON_VARIANTS.TEXT_ONLY}
              className="module-scenes-form__delete-btn"
              dataCy="module-scenes-form__delete-btn"
              disabled={values.length <= MIN_SCENES}
              onClick={() => remove(idx)}
            >
              <FontAwesomeIcon icon={faTrashCan} />
            </Button>
          </Flex>
          <Box className="module-scenes-form__body">
            <Heading
              text="Scene Name"
              variant={TEXT_VARIANTS.SUBHEADING}
              descriptionText={BRIEF_COPY.FORM_DESCRIPTION_SCENE_NAME}
              iconColor={COLORS.NEUTRAL400}
            />
            <Field
              name={`${name}[${idx}].${SCENE_FIELD_NAMES.TITLE}`}
              component={TextInput}
              placeholder={BRIEF_COPY.FORM_PLACEHOLDER_BRIEF_GENERAL}
              className="module-scenes-form__input"
              maxLength={40}
              showCharactersCounter
            />
            <Heading
              text="Description"
              variant={TEXT_VARIANTS.SUBHEADING}
              headerText="Scene Description"
              descriptionText={BRIEF_COPY.FORM_DESCRIPTION_SCENE_DESCRIPTION}
              iconColor={COLORS.NEUTRAL400}
            />
            <Field
              name={`${name}[${idx}].${SCENE_FIELD_NAMES.DESCRIPTION}`}
              key={scene.id || scene.formId}
              component={RichtextInput}
              placeholder={BRIEF_COPY.FORM_PLACEHOLDER_BRIEF_GENERAL}
              className="module-scenes-form__description-input"
              readableWidth
              tabIndex={0}
            />
          </Box>
        </Box>
      ))}
      <Button
        type="button"
        className="module-scenes-form__add-btn"
        dataCy="module-scenes-form__add-btn"
        variant={BUTTON_VARIANTS.TERTIARY}
        disabled={values?.length >= MAX_SCENES}
        onClick={onPush}
      >
        {BRIEF_COPY.BUTTON_ADD_SCENE}
      </Button>
    </Box>
  );
});

export default ModuleScenesForm;
