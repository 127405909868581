export const ORGANIZATION_COPY = {
  // Buttons
  BUTTON_EDIT_ADDRESS_ORGANIZATION: 'Edit',
  BUTTON_ONBOARD_CHECKOUT: 'Confirm Account & Checkout',
  BUTTON_CREATE_ORGANIZATION: 'Create New Organization',
  BUTTON_EDIT_ORGANIZATION: 'Edit Organization',

  // Defaults
  DEFAULT_CARD_NAME: `{brand} ending in {last4}`,

  // Placeholders
  PLACEHOLDER_BRAND_NAME: 'Brand Name',
  PLACEHOLDER_BRAND_WEBSITE: 'Website',
  PLACEHOLDER_BRAND_LOGO: 'Upload Logo',

  // Settings Nav Headers
  SETTINGS_NAV_BILLING: 'Billing',
  SETTINGS_NAV_TEAM_MANAGEMENT: 'Team Management',
  SETTINGS_NAV_ORGANIZATION: 'Organization',

  // Settings Nav
  SETTINGS_NAV_OVERVIEW: 'Overview',
  SETTINGS_NAV_PAYMENT: 'Payment Method',
  SETTINGS_NAV_USERS: 'Users',
  SETTINGS_NAV_ROLES: 'User Roles',
  SETTINGS_NAV_EDIT_DETAILS: 'Details',

  // Headings
  HEADING_ORG_DETAILS: `Organization Details`,
  HEADING_BILLING_ADDRESS_FORM: `Edit Billing Address`,
  HEADING_BILLING_ADDRESS: 'Billing Address',
  HEADING_SWITCH_ORGANIZATIONS: `Switch Organizations`,
  HEADING_ORG_ONBOARD_LOADING: 'Hang tight while we set up your account!',

  // form
  FORM_HEADING_CREATE_ORGANIZATION: `What's the name of your business?`,
  FORM_INFO_CREATE: `All of your brands managed by brkfst are grouped under an "Organization." This allows you to manage multiple brands under one grouping. Your brkfst business account can have multiple organizations and each organization can have multiple brands. This lets you organize your brands in a way that best reflects your business. (E.g., the "Nike" organization could have brands "Nike Sport," "Converse," "Jordan," etc.)`,
  FORM_HEADING_BILLING: `Enter your credit card (you won't be charged yet)`,
  FORM_SUBTITLE_BILLING: 'Your card will not be charged until you launch a campaign',
  FORM_HEADING_ADDRESS: `Billing Address`,
  FORM_SUBTITLE_ADDRESS: `What's your billing address? `,
  FORM_HEADING_BRAND_ADVERTISING: 'What brand are you advertising?',
  FORM_INFO_BRAND_ADVERTISING:
    'Set up brkst marketing for your first brand. This can be the same as your organization name. If you have more than one brand, you can create additional brands once you complete the onboarding process. (E.g., the "Nike" organization could have brands "Nike Sport," "Converse," "Jordan," etc.)',
  FORM_SUMMARY_ORGANIZATION_NAME: 'Organization Name',
  FORM_SUMMARY_ORGANIZATION_WEBSITE: 'Organization Website',
  FORM_SUMMARY_BILLING_INFO: 'Billing Info',
  FORM_SUMMARY_BILLING_ADDRESS: 'Billing Address',
  FORM_SUMMARY_COMPANY_NAME: 'Company Name',
  FORM_SUMMARY_BRAND_NAME: 'Brand Name',
  FORM_SUMMARY_BRAND_WEBSITE: 'Brand Website',
  FORM_SUMMARY_BRAND_SLOGAN: 'Brand Slogan',
  FORM_SUMMARY_ADVERTISING_BUDGET: 'Advertising Budget',
  FORM_SUMMARY_CPA_GOAL: 'CPA Goal',
  FORM_SUMMARY_AUDIENCE: 'Audience',

  // Dropdown options
  OPTION_SWITCH: 'Switch Organizations',
  OPTION_SETTINGS: 'Organization Settings',

  // Image Alts
  ALT_LOADING: 'Loading',
};
