import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import config from 'config';

import {
  accountPlatformAdded,
  accountPlatformChanged,
  accountPlatformRemoved,
  accountPlatformsLoaded,
  accountPlatformsReset,
  getAccountPlatform,
  getAccountPlatforms,
  getFacebookAccountPlatforms,
} from 'features/accountPlatforms/accountPlatforms.slice';
import { useFiles } from 'features/media/useFiles';
import { useSasToken } from 'features/media/useSasToken';
import { getPlatformSnippet } from 'features/performance/performance.slice';
import { adScoreProgressReset } from 'features/ui/ui.slice';

import { apiAction } from 'shared/actions/api';
import { MEDIA } from 'shared/config/media';
import { PERMISSIONS_MAP } from 'shared/config/permissions';
import { RESOURCES } from 'shared/config/resourceNames';
import { API_ACCOUNT_ROUTES } from 'shared/config/routes/api/apiAccountRoutes';
import { ACCOUNT_PLATFORM_TOASTS } from 'shared/config/toasts/accountPlatformToasts';
import { useComponentLoading } from 'shared/hooks/useComponentLoading';
import { usePermissions } from 'shared/hooks/usePermissions';
import { useToasts } from 'shared/hooks/useToasts';
import { AccountPlatform } from 'shared/typings/accountPlatform';
import { AccountPlatformType } from 'shared/typings/accountPlatform/enums';
import { LinkCreator } from 'shared/utilities/linkUtility';
import { GeneralValidator } from 'shared/utilities/validator';

const NEW_PLATFORM_ENTITY = 'newPlatform';

export const useAccountPlatforms = ({
  // @ts-ignore
  accountId: account,
  // @ts-ignore
  accountPlatformId: platform,
  // @ts-ignore
  organizationId: organization,
}: {
  accountId?: number;
  accountPlatformId?: number;
  organizationId?: number;
} = {}) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams<any>();
  const sasToken = useSasToken();

  const accountId = account || +params.accountId;
  const accountPlatformId = platform || +params.accountPlatformId;
  const organizationId = organization || +params.organizationId;

  const { initiateSingleFileUpload } = useFiles();
  const accountPlatforms: AccountPlatform[] = useSelector(getAccountPlatforms);
  // TODO: Fix Types
  // @ts-ignore
  const accountPlatform: AccountPlatform = useSelector(getAccountPlatform(accountPlatformId));
  const platformSnippet = useSelector(getPlatformSnippet(accountPlatformId));
  const facebookAccountPlatforms = useSelector(getFacebookAccountPlatforms);
  const platformName = accountPlatform.platform ? accountPlatform.platform.shortenedName : null;

  const { setErrorToast } = useToasts();

  const { loading: platformsLoading } = useComponentLoading(RESOURCES.ACCOUNT_PLATFORM);
  const { loading: newPlatformLoading } = useComponentLoading(NEW_PLATFORM_ENTITY, false);

  const isSystemGenerated = accountPlatform.type === AccountPlatformType.SYSTEM_GENERATED;

  const resetAccountPlatforms = () => {
    dispatch(accountPlatformsReset({}));
  };

  const resetAdScoreProgress = () => {
    dispatch(adScoreProgressReset());
  };

  const fetchAccountPlatforms = (id) => {
    dispatch(
      apiAction({
        path: {
          route: API_ACCOUNT_ROUTES.ACCOUNT_PLATFORMS,
          variables: {
            accountId: id,
          },
        },
        method: 'GET',
        entity: RESOURCES.ACCOUNT_PLATFORM,
        successAction: accountPlatformsLoaded,
      }),
    );
  };

  const createAccountPlatform = (formData) => {
    dispatch(
      apiAction({
        path: {
          route: API_ACCOUNT_ROUTES.ACCOUNT_PLATFORMS,
          variables: {
            accountId,
          },
        },
        method: 'POST',
        data: formData,
        entity: NEW_PLATFORM_ENTITY,
        successAction: accountPlatformAdded,
        successToast: {
          message: formatMessage({ id: 'CREATED' }, { resource: RESOURCES.ACCOUNT_PLATFORM }),
        },
      }),
    );
  };

  const editAccountPlatform = (formData) => {
    dispatch(
      apiAction({
        path: {
          route: API_ACCOUNT_ROUTES.ACCOUNT_PLATFORM,
          variables: {
            accountId,
            accountPlatformId: accountPlatform.id,
          },
        },
        method: 'PATCH',
        data: formData,
        successAction: accountPlatformChanged,
        successToast: {
          message: ACCOUNT_PLATFORM_TOASTS.ACCOUNT_PLATFORM_UPDATED,
        },
      }),
    );
  };

  const deleteAccountPlatform = () => {
    dispatch(
      apiAction({
        path: {
          route: API_ACCOUNT_ROUTES.ACCOUNT_PLATFORM,
          variables: {
            accountId,
            accountPlatformId,
          },
        },
        method: 'DELETE',
        data: { id: accountPlatformId },
        successAction: accountPlatformRemoved,
        navigate,
        pushFunction: () =>
          LinkCreator.createLink({
            routeKey: 'PLATFORMS',
            variables: {
              organizationId,
              accountId,
            },
          }),
        successToast: {
          message: ACCOUNT_PLATFORM_TOASTS.ACCOUNT_PLATFORM_DELETED,
        },
      }),
    );
  };

  const { isAuthorized } = usePermissions();

  const editAudience = async (audienceFile) => {
    // is a re-upload
    const audienceId = accountPlatform?.facebookCustomAudience?.id;
    // send the data as FormData.
    const authorizedToEdit = isAuthorized(organizationId, PERMISSIONS_MAP.EDIT_ACCOUNT.API); //TODO should this be EDIT_ACCOUNT_PLATFORM?
    if (!authorizedToEdit) {
      setErrorToast({
        message: ACCOUNT_PLATFORM_TOASTS.EDIT_DENIED,
      });
    } else {
      if (sasToken)
        await initiateSingleFileUpload(
          {
            file: audienceFile.file,
            fileDirectory: config.FB_CUSTOMER_FILE_DIR,
            dispatchLoader: true,
            metadata: {
              audienceId,
              accountPlatformId,
              accessId: accountPlatform.accessId,
            },
          },
          // TODO determine if a specific validator should be made for this
          new GeneralValidator([MEDIA.MIME_TYPES.CSV, MEDIA.EXTENSIONS.CSV]),
        );
    }
  };

  return {
    accountPlatform,
    accountPlatforms,
    createAccountPlatform,
    deleteAccountPlatform,
    editAccountPlatform,
    editAudience,
    facebookAccountPlatforms,
    fetchAccountPlatforms,
    isSystemGenerated,
    newPlatformLoading,
    platformName,
    platformsLoading,
    platformSnippet,
    resetAccountPlatforms,
    resetAdScoreProgress,
  };
};
