import {
  faCheckDouble,
  faCircleInfo,
  faCreditCard,
  faGripDotsVertical,
  faLayerGroup,
} from '@fortawesome/pro-solid-svg-icons';
import * as Yup from 'yup';

import { SelectValue } from 'shared/components/molecules/Select';
import { getCategoryTagInputSchema } from 'shared/components/molecules/TagInput';
import { richTextSchema } from 'shared/components/Richtext';
import { EMPTY_TEXT, RICHTEXT_DEFAULT_VALUE } from 'shared/components/Richtext';
import { RichtextValue } from 'shared/components/Richtext/shared/types';
import { RichTextFormats } from 'shared/components/Richtext/shared/types';
import { isRichtextEmpty } from 'shared/components/Richtext/shared/utils';

import { BRIEF_COPY } from 'shared/config/copy';
import {
  CONSTRAINT_LIMITS,
  FACEBOOK_VIDEO_MAX_DURATION,
  FACEBOOK_VIDEO_MIN_DURATION,
  MESSAGES,
} from 'shared/config/validations';
import { intl } from 'shared/lib/intl';
import { AccountPricingConfig } from 'shared/typings/account';
import { State } from 'shared/typings/address';
import { Brief, ModuleFileType, Scene } from 'shared/typings/briefs';
import { BriefStatus } from 'shared/typings/briefs/enums';
import { BrkfstTag } from 'shared/typings/tag';
import { isValidDomain } from 'shared/utilities/stringUtility';
import { Override } from 'shared/utilities/types';

import { moduleDefaults } from './pages';

type Title = 'Overview' | 'Pricing' | 'Creator Requirements' | 'Module Requirements' | 'Content Guidelines';
type TitleConfig = Record<Title, { icon: any; title: string }>;

const PRICING_MIN = 0;
const PAYOUT_MIN = 0;
const PRICING_PAYOUT_MAX = 10000;
export const INTERNAL_NOTE_MAX_LENGTH = 150;

export const BRIEF_FIELDNAMES: Record<Title, Record<string, string>> = {
  Overview: {
    NAME: 'name',
    WEBSITE: 'website',
    DESCRIPTION: 'description',
    SHIPPING_REQUIREMENT: 'shippingRequired',
  },

  Pricing: {
    CREATOR_PAYOUT: 'creatorPayout',
    ELITE_CREATOR_PAYOUT: 'eliteCreatorPayout',
    CREATOR_APPROVAL_PRICING: 'creatorApprovalPricing',
    ELITE_CREATOR_APPROVAL_PRICING: 'eliteCreatorApprovalPricing',
    INTERNAL_NOTE: 'internalNote',
    CREATIVE_ONLY: 'creativeOnly',
  },

  ['Module Requirements']: { MODULES: 'modules' },

  ['Creator Requirements']: {
    CREATOR_REQUIREMENTS: 'creatorRequirements',
    STATES: 'states',
    INSHOP_REQUIREMENT: 'inShopRequired',
    AUTO_RESPONSE: 'autoResponse',
    INVITE_ONLY: 'inviteOnly',
  },
  ['Content Guidelines']: {
    CONTENT_GUIDELINES: 'contentGuidelines',
    DOS_AND_DONTS: 'dosAndDonts',
    VALUE_PROPOSITIONS: 'valuePropositions',
  },
};

const payoutSchema = Yup.string().money({
  min: PAYOUT_MIN,
  max: PRICING_PAYOUT_MAX,
  message: `Payout needs to be between $${PAYOUT_MIN} and $${PRICING_PAYOUT_MAX}`,
});

const pricingSchema = Yup.string().money({
  min: PRICING_MIN,
  max: PRICING_PAYOUT_MAX,
  message: `Pricing needs to be between $${PRICING_MIN} and $${PRICING_PAYOUT_MAX}`,
});

const contentGuidelinesSchema = {
  contentGuidelines: Yup.mixed().nullable(),
  dosAndDonts: Yup.mixed().nullable(),
  valuePropositions: Yup.mixed().nullable(),
};

export const overviewValidationSchema = {
  name: Yup.string()
    .customRequiredString()
    .max(CONSTRAINT_LIMITS.MAX_BRIEF_TITLE_LENGTH)
    .required(BRIEF_COPY.VALIDATION_INCOMPLETE_FIELD)
    .test({
      name: 'name',
      message: BRIEF_COPY.VALIDATION_NAME,
      test(name) {
        if (name) return !name.includes('}');
        return false;
      },
    }),
  status: Yup.string(),
  description: richTextSchema.required(BRIEF_COPY.VALIDATION_INCOMPLETE_FIELD),
  website: Yup.array().of(
    Yup.string()
      .nullable()
      .test({
        name: 'domain',
        message: MESSAGES.DOMAIN,
        test(domain) {
          if (domain) return isValidDomain(domain);
          return true;
        },
      }),
  ),
  shippingRequired: Yup.boolean(),
};

export const creatorRequirementsValidationSchema = {
  creatorRequirements: Yup.mixed().nullable(),
  inShopRequired: Yup.boolean(),
  states: Yup.array().when('inShopRequired', {
    is: (value) => value,
    then: Yup.array(
      Yup.object({
        label: Yup.string().required(BRIEF_COPY.VALIDATION_INCOMPLETE_FIELD),
        value: Yup.object({
          id: Yup.number().required(BRIEF_COPY.VALIDATION_INCOMPLETE_FIELD),
          name: Yup.string().required(BRIEF_COPY.VALIDATION_INCOMPLETE_FIELD),
          code: Yup.string().required(BRIEF_COPY.VALIDATION_INCOMPLETE_FIELD),
        }),
      }),
    ).test('stateRequirement', BRIEF_COPY.VALIDATION_STATE_REQUIREMENT, (value) => (value?.length || 0) > 0),
    otherwise: Yup.array().nullable(),
  }),
  autoResponse: Yup.string().trim().required(BRIEF_COPY.VALIDATION_INCOMPLETE_FIELD),
  inviteOnly: Yup.boolean(),
};

export const pricingValidationMarketerSchema = {
  creatorApprovalPricing: pricingSchema,
  eliteCreatorApprovalPricing: pricingSchema,
  creativeOnly: Yup.boolean(),
  internalNote: Yup.string().nullable().trim().max(INTERNAL_NOTE_MAX_LENGTH),
};

export const pricingValidationSuperadminSchema = {
  creatorPayout: payoutSchema,
  eliteCreatorPayout: payoutSchema,
  ...pricingValidationMarketerSchema,
};

export const moduleRequirementsValidationSchema = {
  modules: Yup.array().of(
    Yup.object({
      name: Yup.string().required(BRIEF_COPY.VALIDATION_INCOMPLETE_FIELD),
      content: richTextSchema,
      tags: getCategoryTagInputSchema(true),
      fileType: Yup.string().required(BRIEF_COPY.VALIDATION_INCOMPLETE_FIELD),
      durationRange: Yup.array()
        .of(
          Yup.number()
            .integer(BRIEF_COPY.VALIDATION_DURATION_INTEGER)
            .min(
              FACEBOOK_VIDEO_MIN_DURATION,
              intl.formatMessage(
                { id: 'VALIDATION_MIN_DURATION' },
                {
                  minDuration: FACEBOOK_VIDEO_MIN_DURATION,
                },
              ),
            )
            .max(
              FACEBOOK_VIDEO_MAX_DURATION,
              intl.formatMessage(
                { id: 'VALIDATION_MAX_DURATION' },
                {
                  maxDuration: FACEBOOK_VIDEO_MAX_DURATION,
                },
              ),
            ),
        )
        .test({
          name: 'minMax',
          message: BRIEF_COPY.VALIDATION_DURATION,
          test(value) {
            if (value !== undefined) {
              const [minVal, maxVal] = value;
              return minVal !== undefined && maxVal !== undefined && minVal < maxVal;
            }
            return false;
          },
        }),
      scenes: Yup.array().of(
        Yup.object().shape(
          {
            title: Yup.string().when('description', {
              is: (value) => !isRichtextEmpty(value),
              then: Yup.string()
                .customRequiredString()
                .max(255, intl.formatMessage({ id: 'ERROR_STRING_LENGTH' }, { numChar: 255, entity: 'title' })),
              otherwise: Yup.string().optional().nullable(),
            }),
            description: Yup.mixed().when('title', {
              is: (value) => value,
              then: richTextSchema.required(BRIEF_COPY.VALIDATION_INCOMPLETE_FIELD),
              otherwise: Yup.mixed().nullable(),
            }),
          },
          [['description', 'title']],
        ),
      ),
    }),
  ),
};

const getSchema = (isSuperadmin: boolean) => {
  return Yup.object({
    ...overviewValidationSchema,
    ...creatorRequirementsValidationSchema,
    ...(isSuperadmin ? pricingValidationSuperadminSchema : pricingValidationMarketerSchema),
    ...moduleRequirementsValidationSchema,
    ...contentGuidelinesSchema,
  });
};

export const getBriefFormConfig = (
  isSuperadmin: boolean,
): {
  STEP_TITLES: TitleConfig;
  validationSchema: any;
} => {
  const STEP_TITLES: TitleConfig = {
    Overview: {
      title: 'Overview',
      icon: faLayerGroup,
    },
    Pricing: {
      title: 'Pricing',
      icon: faCreditCard,
    },
    ['Creator Requirements']: {
      title: 'Creator Requirements',
      icon: faCircleInfo,
    },
    ['Module Requirements']: {
      title: 'Module Requirements',
      icon: faGripDotsVertical,
    },
    ['Content Guidelines']: {
      title: 'Content Guidelines',
      icon: faCheckDouble,
    },
  };

  const validationSchema = getSchema(isSuperadmin);

  if (isSuperadmin) {
    return {
      STEP_TITLES,
      validationSchema,
    };
  } else {
    return {
      STEP_TITLES,
      validationSchema,
    };
  }
};

export type ModuleSceneValues = Pick<Scene, 'title' | 'description'> & { id?: number; formId?: string };

export type BriefModuleValues = {
  tags: Pick<BrkfstTag, 'value' | 'category' | 'type'>[];
  content: RichtextValue;
  name: string;
  fileType: ModuleFileType;
  durationRange: number[];
  scenes: ModuleSceneValues[];
  id: string | number;
};

type CommonBriefProps = Pick<
  Brief,
  | 'name'
  | 'description'
  | 'autoResponse'
  | 'website'
  | 'status'
  | 'description'
  | 'modules'
  | 'inviteOnly'
  | 'shippingRequired'
  | 'creativeOnly'
  | 'internalNote'
  | 'contentGuidelines'
  | 'dosAndDonts'
  | 'valuePropositions'
  | 'creatorRequirements'
>;

// Override those that have different datatypes in the form, add ones used only in the form.
export type BriefValues = Override<
  CommonBriefProps,
  {
    modules: BriefModuleValues[];
    website?: string[];
    creatorPayout: number | string;
    eliteCreatorPayout: number | string;
    creatorApprovalPricing: number | string;
    eliteCreatorApprovalPricing: number | string;
    inShopRequired?: boolean;
    states?: SelectValue<State>[];
  }
>;

export const SCENE_FIELD_NAMES = {
  TITLE: 'title',
  DESCRIPTION: 'description',
};

export const MIN_SCENES = 0;
export const MAX_SCENES = 10;

export function briefToInitialValues(briefIn?: Brief, pricing?: AccountPricingConfig): BriefValues {
  const formattedModules = briefIn?.modules?.map((module) => ({
    ...module,
    content: JSON.parse(module.content),
    tags: module.tags,
    fileType: module.fileType,
    durationRange: [module.minAssetDuration || 0, module.maxAssetDuration || FACEBOOK_VIDEO_MAX_DURATION],
    scenes: module.scenes.map(({ description, ...scene }) => ({
      ...scene,
      description: JSON.parse(description),
    })),
  }));

  const basicFields = {
    name: briefIn?.name || '',
    status: briefIn?.status || BriefStatus.DRAFT,
    website: briefIn?.website || [''],
    description: briefIn?.description ? JSON.parse(briefIn?.description) : RICHTEXT_DEFAULT_VALUE,
    contentGuidelines: briefIn?.contentGuidelines ? JSON.parse(briefIn?.contentGuidelines) : RICHTEXT_DEFAULT_VALUE,
    creatorRequirements: briefIn?.creatorRequirements
      ? JSON.parse(briefIn?.creatorRequirements)
      : RICHTEXT_DEFAULT_VALUE,
    dosAndDonts: briefIn?.dosAndDonts ? JSON.parse(briefIn?.dosAndDonts) : RICHTEXT_DEFAULT_VALUE,
    valuePropositions: briefIn?.valuePropositions ? JSON.parse(briefIn?.valuePropositions) : RICHTEXT_DEFAULT_VALUE,
    autoResponse: briefIn?.autoResponse || '',
    inviteOnly: briefIn?.inviteOnly || false,
    shippingRequired: briefIn?.shippingRequired || false,
    inShopRequired: Boolean(briefIn?.states?.length) || false,
    creativeOnly: briefIn?.creativeOnly || false,
    modules: formattedModules || [],
    states:
      briefIn?.states?.map((state): SelectValue<State> => {
        return { label: state.code || '', value: state };
      }) || [],
    internalNote: briefIn?.internalNote || '',
  };

  const pricingFields = {
    creatorPayout: briefIn?.creativeOnly
      ? pricing?.creativeOnlyCreatorPayout || 0
      : pricing?.performanceCreatorPayout || 0,
    eliteCreatorPayout: briefIn?.creativeOnly
      ? pricing?.creativeOnlyEliteCreatorPayout || 0
      : pricing?.performanceEliteCreatorPayout || 0,
    creatorApprovalPricing: briefIn?.creativeOnly
      ? pricing?.creativeOnlyCreatorApprovalPricing || 0
      : pricing?.performanceCreatorApprovalPricing || 0,
    eliteCreatorApprovalPricing: briefIn?.creativeOnly
      ? pricing?.creativeOnlyEliteCreatorApprovalPricing || 0
      : pricing?.performanceEliteCreatorApprovalPricing || 0,
  };

  return {
    ...basicFields,
    creatorPayout: briefIn?.creatorPayout ?? pricingFields.creatorPayout,
    eliteCreatorPayout: briefIn?.eliteCreatorPayout ?? pricingFields.eliteCreatorPayout,
    creatorApprovalPricing: briefIn?.creatorApprovalPricing ?? pricingFields.creatorApprovalPricing,
    eliteCreatorApprovalPricing: briefIn?.eliteCreatorApprovalPricing ?? pricingFields.eliteCreatorApprovalPricing,
  };
}

export function defaultBriefValues(pricing?: AccountPricingConfig): BriefValues {
  // We assume that the brief is not creative only at the start since it is unchecked by default, leaving this here for now since we may update the flow in the future to pick if it is creative only or not before starting the brief.
  const creativeOnly = false;
  return {
    modules: [moduleDefaults],
    description: [
      {
        type: RichTextFormats.PARAGRAPH,
        children: [EMPTY_TEXT],
      },
    ],
    contentGuidelines: [
      {
        type: RichTextFormats.PARAGRAPH,
        children: [EMPTY_TEXT],
      },
    ],
    creatorRequirements: [
      {
        type: RichTextFormats.PARAGRAPH,
        children: [EMPTY_TEXT],
      },
    ],
    dosAndDonts: [
      {
        type: RichTextFormats.PARAGRAPH,
        children: [EMPTY_TEXT],
      },
    ],
    valuePropositions: [
      {
        type: RichTextFormats.PARAGRAPH,
        children: [EMPTY_TEXT],
      },
    ],
    name: '',
    autoResponse: '',
    inviteOnly: false,
    shippingRequired: false,
    creativeOnly: false,
    creatorPayout: creativeOnly ? pricing?.creativeOnlyCreatorPayout || 0 : pricing?.performanceCreatorPayout || 0,
    eliteCreatorPayout: creativeOnly
      ? pricing?.creativeOnlyEliteCreatorPayout || 0
      : pricing?.performanceEliteCreatorPayout || 0,
    creatorApprovalPricing: creativeOnly
      ? pricing?.creativeOnlyCreatorApprovalPricing || 0
      : pricing?.performanceCreatorApprovalPricing || 0,
    eliteCreatorApprovalPricing: creativeOnly
      ? pricing?.creativeOnlyEliteCreatorApprovalPricing || 0
      : pricing?.performanceEliteCreatorApprovalPricing || 0,
  };
}
