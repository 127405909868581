import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';

import NewAccount from 'features/accounts/pages/NewAccount';
import MessageCenter from 'features/messages/pages/MessageCenter/MessageCenter';
import { useMessaging } from 'features/messages/useMessaging';
import OrgHome from 'features/organizations/pages/OrgHome/OrgHome';
import { useOrganizations } from 'features/organizations/useOrganizations';
import { useOrgUsers } from 'features/organizations/useOrgUsers';
import { CenteredSpinnerContainer } from 'features/ui/CenteredSpinner';
import { NoAccessMsg } from 'features/ui/pages/ErrorPages';

import { RESOURCES } from 'shared/config/resourceNames';
import { OrganizationRouteParams } from 'shared/config/routes/brands/matchTypes';
import { GENERAL_ROUTES } from 'shared/config/routes/generalRoutes';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { AccountRoutes } from 'shared/router/brandRoutes/AccountRoutes';
import OrgSettingRoutes from 'shared/router/brandRoutes/OrgSettingsRoutes';

const OrgRoutes = () => {
  const params = useParams<OrganizationRouteParams>();
  const navigate = useNavigate();
  const organizationId = +params.organizationId;

  const { setUnreadChannels } = useMessaging();
  const { organization, loading, fetchOrg, toggleOrgLoading } = useOrganizations(organizationId);
  const { fetchOrgUsers } = useOrgUsers(organizationId);
  const { lastVisitedOrg, updateLastVisitedOrg, isSuperadmin } = useCurrentUser();

  const showMessageIcon = organization?.chatEnabled || isSuperadmin;

  useEffect(() => {
    if (organizationId || organizationId === 0) {
      fetchOrg();
      fetchOrgUsers();
    }
    return () => {
      /**
       * Many resources under the org route default
       * to having loading be true before api requests
       * to fetch them are made to avoid any flickering
       * in the UI that could occur when the page is
       * loading but before the api request can be made.
       *
       * When we navigate away from an org route page, and
       * the org routes are unmounted we need to toggle
       * these back to true so that when navigating back
       * the loading values are true by default again.
       *
       */
      toggleOrgLoading(true);
    };
  }, [organizationId]);

  useEffect(() => {
    if (lastVisitedOrg !== organizationId && !isEmpty(organization) && !loading) {
      updateLastVisitedOrg(organizationId);
    }
    if (!isEmpty(organization) && !loading) {
      setUnreadChannels();
    }
  }, [organization, organizationId, loading]);

  useEffect(() => {
    if (isEmpty(organization) && !loading) {
      navigate({ pathname: GENERAL_ROUTES.NO_ACCESS }, { state: { type: `${RESOURCES.ORGANIZATION}` } });
    }
  }, [organization, loading, organizationId]);

  // match.path should be /:organizationId
  return loading ? (
    <CenteredSpinnerContainer />
  ) : (
    <Routes>
      <Route index element={<OrgHome />} />
      <Route path="new-account" element={<NewAccount />} />
      <Route path="account/:accountId/*" element={<AccountRoutes />} />
      <Route path="settings/*" element={<OrgSettingRoutes />} />
      <Route
        path="messaging"
        element={showMessageIcon ? <MessageCenter /> : <NoAccessMsg type={RESOURCES.ORGANIZATION_MESSAGING} />}
      />
      <Route path="*" element={<Navigate to="/not-found" />} />
    </Routes>
  );
};

export default OrgRoutes;
