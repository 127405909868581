import React from 'react';

import ModuleScene from 'features/briefs/components/atoms/ModuleScene';
import Tags, { TagCategory } from 'shared/components/atoms/Tags';
import { Box, Flex, Text } from 'shared/components/display';
import Richtext from 'shared/components/Richtext';

import { Module } from 'shared/typings/briefs';
import { FILE_TYPE_OPTIONS } from 'features/briefs/components/organisms/BriefMultiPageForm/pages/BriefModulesForm/BriefModulesForm';

import styles from './styles';

const settings = {
  templates: {
    tag: TagCategory,
  },
  duplicates: true,
};

interface Props {
  module: Module;
  showTags?: boolean;
}
const BriefModule: React.FC<Props> = ({ module, showTags = false }) => {
  return (
    <Box className="brief-module" css={styles}>
      <Richtext value={JSON.parse(module.content)} readOnly key={module.id} />
      {showTags && <Tags className="brief-module__tags" value={module.tags} settings={settings} readOnly />}
      <Box className="brief-module__file-type">
        <Text as="span" className="brief-module__file-type-label">
          File Type:{' '}
        </Text>
        <Text as="span">{FILE_TYPE_OPTIONS.find((x) => x.value === module.fileType)?.label}</Text>
      </Box>
      {module.fileType !== 'image' && (
        <Box className="brief-module__duration">
          <Text as="span" className="brief-module__duration-label">
            Duration:{' '}
          </Text>
          <Text as="span">{`${module.minAssetDuration}s - ${module.maxAssetDuration}s`}</Text>
        </Box>
      )}
      <Flex className="brief-module__scene-wrapper">
        {module.scenes?.map((scene, i) => (
          <ModuleScene key={scene.id} scene={scene} sceneNum={scene.order} />
        ))}
      </Flex>
    </Box>
  );
};

export default BriefModule;
