import React from 'react';

import ExternalLink from 'shared/components/atoms/ExternalLink/ExternalLink';
import { DefinitionList, ListDetail } from 'shared/components/molecules/DefinitionList';

import { useIntl } from 'shared/lib/intl';
import { BrkfstUser } from 'shared/typings/user';

interface Props {
  user: BrkfstUser;
}

const getTiktokUrl = (userName: string) => `https://www.tiktok.com/@${userName}`;
const getInstagramUrl = (userName: string) => `https://www.instagram.com/${userName}`;

const CreatorUserFields: React.FC<Props> = ({ user }) => {
  const { formatMessage } = useIntl();
  return (
    <DefinitionList>
      <ListDetail text="Portfolio Link" hide={!user.portfolioLink}>
        {user.portfolioLink ? (
          <ExternalLink href={user.portfolioLink} />
        ) : (
          formatMessage(
            {
              id: 'PLACEHOLDER_PROFILE',
            },
            { field: 'portfolio link' },
          )
        )}
      </ListDetail>
      <ListDetail text="TikTok" hide={!user.socialMediaInfo?.tiktok}>
        <ExternalLink
          href={getTiktokUrl(user.socialMediaInfo?.tiktok || '')}
          text={user.socialMediaInfo?.tiktok || ''}
        />
      </ListDetail>
      <ListDetail text="Instagram" hide={!user.socialMediaInfo?.instagram}>
        <ExternalLink
          href={getInstagramUrl(user.socialMediaInfo?.instagram || '')}
          text={user.socialMediaInfo?.instagram || ''}
        />
      </ListDetail>
    </DefinitionList>
  );
};

export default CreatorUserFields;
