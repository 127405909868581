/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
// The 3 lines above are necessary because we're using the css prop on a native html element
import React from 'react';
import cs from 'classnames';

import labelStyles, { MODIFIERS, BASE_CLASS } from './styles';

type StyleProps = {
  active?: boolean;
  disabled?: boolean;
  hasValue?: boolean;
  isInvalid?: boolean;
};

type LabelProps = JSX.IntrinsicElements['label'] & { dataCy?: string };

const InputLabel: React.FC<LabelProps & StyleProps> = ({
  className,
  active,
  disabled,
  hasValue,
  isInvalid,
  dataCy,
  ...props
}) => (
  <label
    {...props}
    data-cy={dataCy}
    css={labelStyles}
    className={cs(className, {
      [BASE_CLASS]: true,
      [BASE_CLASS.concat(MODIFIERS.ACTIVE)]: active,
      [BASE_CLASS.concat(MODIFIERS.DISABLED)]: disabled,
      [BASE_CLASS.concat(MODIFIERS.HAS_VALUE)]: hasValue,
      [BASE_CLASS.concat(MODIFIERS.INVALID)]: isInvalid,
    })}
  />
);

export default InputLabel;
