import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  accountAdded,
  accountBannerRemoved,
  accountChanged,
  accountLogoRemoved,
  accountPricingChanged,
  accountRemoved,
  accountsLoaded,
  getAccount,
  getAccounts,
} from 'features/accounts/accounts.slice';
import { brandAssetRemoved, brandAssetsLoaded, getBrandAssets } from 'features/accounts/brandAssets.slice';
import { channelsLoaded, getAccountChannels } from 'features/accounts/channels.slice';
import { ACCEPTABLE_BANNER_FILE_TYPES } from 'features/accounts/components/organisms/BannerLogoForm/BannerLogoForm';
import { useFiles } from 'features/media/useFiles';
import { getAccountUsers } from 'features/organizations/orgUsers.slice';
import { getAccountsWithSnippet, snippetDataAdded } from 'features/performance/performance.slice';
import { componentLoadingToggled } from 'features/ui/ui.slice';

import { apiAction } from 'shared/actions/api';
import { ALL_BRKFST_SUPPORTED_FILE_FORMATS, BRKFST_SUPPORTED_IMAGE_FORMATS } from 'shared/config/fileFormats';
import { PERMISSIONS_MAP } from 'shared/config/permissions';
import { RESOURCES } from 'shared/config/resourceNames';
import { API_ACCOUNT_ROUTES } from 'shared/config/routes/api/apiAccountRoutes';
import { API_PERFORMANCE_ROUTES } from 'shared/config/routes/api/apiPerformanceRoutes';
import { ACCOUNT_TOASTS } from 'shared/config/toasts';
import { useComponentLoading, useMultipleLoading } from 'shared/hooks/useComponentLoading';
import { usePermissions } from 'shared/hooks/usePermissions';
import { useToasts } from 'shared/hooks/useToasts';
import { AccountPricingConfig, BrkfstAccount } from 'shared/typings/account';
import { UploadOperation } from 'shared/typings/file';
import { Toast } from 'shared/typings/toasts';
import { getIncrements, LAST_7_DAYS_PRESET } from 'shared/utilities/dateUtility';
import { LinkCreator } from 'shared/utilities/linkUtility';
import { GeneralValidator } from 'shared/utilities/validator';

const getSnippetLoadingEntity = (id) => `SNIPPET-${id}`;
const DELETE_BRAND_ASSET_ENTITY = 'deleteBrandAsset';

export const useAccounts = ({ accountId = '' }: { accountId?: string } = {}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const accounts: BrkfstAccount[] = useSelector(getAccounts);
  const account: BrkfstAccount = useSelector(getAccount(accountId));
  const accountUsers = useSelector(getAccountUsers(accountId));
  const accountChannels = useSelector(getAccountChannels());
  const brandAssets = useSelector(getBrandAssets(accountId));

  const accountsWithSnippets = useSelector(getAccountsWithSnippet);

  const { setErrorToast } = useToasts();
  const { isAuthorized } = usePermissions();
  const { initiateSingleFileUpload, initiateMultipleFilesUpload } = useFiles();

  const snippetLoadingKeys = accounts
    .map((acc) => {
      if (acc.accountPlatformIds.length) {
        return getSnippetLoadingEntity(acc.id);
      }
      return null;
    })
    .filter((a) => a);
  const { loading: multipleSnippetsLoading } = useMultipleLoading(snippetLoadingKeys);

  const { loading: snippetLoading } = useComponentLoading(getSnippetLoadingEntity(accountId));

  const { loading } = useComponentLoading(RESOURCES.ACCOUNT);
  const { loading: loadingAccountLogo } = useComponentLoading(RESOURCES.ACCOUNT_LOGO);
  const { loading: loadingAccountChannels } = useComponentLoading(RESOURCES.CHANNEL);
  const { loading: loadingAccountBanner } = useComponentLoading(RESOURCES.ACCOUNT_BANNER, false);
  const { loading: loadingAccountSettings } = useComponentLoading(RESOURCES.ACCOUNT_SETTINGS, false);
  const { loading: loadingPerformanceIndicator } = useComponentLoading(RESOURCES.PERFORMANCE_INDICATOR);
  const { loading: loadingBrandAsset } = useComponentLoading(RESOURCES.BRAND_ASSET, false);
  const { loading: loadingAccountPricing } = useComponentLoading(RESOURCES.ACCOUNT_PRICING, false);
  const { loading: deleteBrandAssetInProgress } = useComponentLoading(DELETE_BRAND_ASSET_ENTITY, false);

  const getAllAccounts = () => {
    dispatch(
      apiAction({
        path: {
          route: API_ACCOUNT_ROUTES.ACCOUNTS,
        },
        method: 'GET',
        successAction: accountsLoaded,
        entity: RESOURCES.ACCOUNT,
        navigate,
      }),
    );
  };

  const createAccount = (data, onError: () => void) => {
    dispatch(
      apiAction({
        path: {
          route: API_ACCOUNT_ROUTES.ACCOUNTS,
        },
        method: 'POST',
        data,
        successAction: accountAdded,
        successToast: {
          message: ACCOUNT_TOASTS.ACCOUNT_CREATED,
        },
        entity: RESOURCES.ACCOUNT,
        navigate,
        onError: onError,
        pushFunction: (newAccount) =>
          LinkCreator.createLink({
            routeKey: 'ACCOUNT',
            variables: {
              organizationId: newAccount.organizationId,
              accountId: newAccount.id,
            },
          }),
      }),
    );
  };

  const deleteAccount = ({
    id,
    organizationId: orgId,
    pushFunction,
  }: {
    id: string;
    organizationId: number;
    pushFunction?: () => void;
  }) => {
    const authorizedToEdit = isAuthorized(orgId, PERMISSIONS_MAP.DELETE_ACCOUNT.API);
    if (!authorizedToEdit) {
      setErrorToast({
        message: ACCOUNT_TOASTS.ACCOUNT_DELETED,
      });
    } else {
      dispatch(
        apiAction({
          path: {
            route: API_ACCOUNT_ROUTES.ACCOUNT,
            variables: {
              accountId: id,
            },
          },
          method: 'DELETE',
          successAction: accountRemoved,
          successToast: {
            message: ACCOUNT_TOASTS.ACCOUNT_DELETED,
          },
          entity: RESOURCES.ACCOUNT,
          navigate,
          pushFunction,
        }),
      );
    }
  };

  const editAccount = ({
    data,
    pushFunction,
    successToast,
  }: {
    data: Partial<BrkfstAccount> & Pick<BrkfstAccount, 'organizationId' | 'id'>;
    pushFunction?: () => string;
    successToast: Toast;
  }) => {
    const authorizedToEdit = isAuthorized(data.organizationId, PERMISSIONS_MAP.EDIT_ACCOUNT.API);
    if (!authorizedToEdit) {
      setErrorToast({
        message: ACCOUNT_TOASTS.EDIT_ACCOUNT_DENIED,
      });
    } else {
      dispatch(
        apiAction({
          path: {
            route: API_ACCOUNT_ROUTES.ACCOUNT,
            variables: {
              accountId: data.id,
            },
          },
          method: 'PATCH',
          data,
          successAction: accountChanged,
          entity: RESOURCES.ACCOUNT_SETTINGS,
          navigate,
          pushFunction,
          successToast: {
            message: ACCOUNT_TOASTS.ACCOUNT_UPDATED,
          },
        }),
      );
    }
  };

  const dispatchLoading = (component: string, loading: boolean = true) => {
    dispatch(componentLoadingToggled({ component, loading }));
  };

  const addAccountLogo = async (newLogo) => {
    dispatchLoading(RESOURCES.ACCOUNT_LOGO);
    await initiateSingleFileUpload(
      {
        file: newLogo.file,
        metadata: {
          accountId: accountId.toString(),
        },
        dispatchLoader: false,
      },
      new GeneralValidator(BRKFST_SUPPORTED_IMAGE_FORMATS),
      (errors) => {
        dispatchLoading(RESOURCES.ACCOUNT_LOGO, false);
        setErrorToast({ message: errors?.[0] });
      },
    );
  };

  const addAccountBanner = async (banner) => {
    dispatchLoading(RESOURCES.ACCOUNT_BANNER);
    await initiateSingleFileUpload(
      {
        file: banner.file,
        metadata: {
          accountId: accountId.toString(),
          isBannerLogo: true,
        },
        dispatchLoader: false,
      },
      new GeneralValidator(ACCEPTABLE_BANNER_FILE_TYPES),
      (errors) => {
        dispatchLoading(RESOURCES.ACCOUNT_BANNER, false);
        setErrorToast({ message: errors?.[0] });
      },
    );
  };

  const removeAccountLogo = (fileId) => {
    dispatch(
      apiAction({
        path: {
          route: API_ACCOUNT_ROUTES.ACCOUNT_LOGO_FILE,
          variables: {
            accountId,
            fileId,
          },
        },
        method: 'DELETE',
        successAction: accountLogoRemoved,
        entity: RESOURCES.ACCOUNT_LOGO,
        successToast: {
          message: ACCOUNT_TOASTS.LOGO_REMOVED,
        },
      }),
    );
  };

  const removeAccountBanner = (fileId) => {
    dispatch(
      apiAction({
        path: {
          route: API_ACCOUNT_ROUTES.ACCOUNT_LOGO_FILE,
          variables: {
            accountId,
            fileId,
          },
        },
        method: 'DELETE',
        successAction: accountBannerRemoved,
        entity: RESOURCES.ACCOUNT_BANNER,
        successToast: {
          message: ACCOUNT_TOASTS.BANNER_REMOVED,
        },
      }),
    );
  };

  const deleteBrandAsset = (fileId, accountIdIn) => {
    dispatch(
      apiAction({
        path: {
          route: API_ACCOUNT_ROUTES.DELETE_BRAND_ASSET,
          variables: {
            id: accountIdIn,
            fileId,
          },
        },
        method: 'DELETE',
        successAction: brandAssetRemoved,
        entity: DELETE_BRAND_ASSET_ENTITY,
        successToast: {
          message: ACCOUNT_TOASTS.BRAND_ASSET_DELETED,
        },
      }),
    );
  };

  const fetchBrandAssets = (accountId) => {
    dispatch(
      apiAction({
        path: {
          route: API_ACCOUNT_ROUTES.GET_BRAND_ASSETS,
          variables: {
            accountId,
          },
        },
        params: {
          accountId,
        },
        successAction: brandAssetsLoaded,
        entity: RESOURCES.BRAND_ASSET,
      }),
    );
  };

  const uploadBrandAsset = async (files: FileList, accountId: number | string) => {
    dispatch(
      componentLoadingToggled({
        component: RESOURCES.BRAND_ASSET,
        loading: true,
      }),
    );
    const formattedFiles: UploadOperation[] = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      formattedFiles.push({
        file,
        metadata: {
          accountId,
          isBrandAsset: true,
        },
        dispatchLoader: false,
      });
    }
    await initiateMultipleFilesUpload(formattedFiles, new GeneralValidator(ALL_BRKFST_SUPPORTED_FILE_FORMATS));
  };

  // snippet functions
  // until performance provider is transitioned to redux

  const getSnippetParams = (accountPlatformIds) => {
    const incremented = getIncrements(LAST_7_DAYS_PRESET, 1);
    const dateRanges = incremented.map((day) => ({
      startDate: day,
      endDate: day,
    }));

    return {
      fields: 'Spend',
      accountPlatformIds,
      level: 'account',
      datePreset: LAST_7_DAYS_PRESET,
      dateRanges: JSON.stringify(dateRanges),
    };
  };

  const requestSnippets = (accountObj) => {
    const { accountPlatformIds, id } = accountObj;
    const requestParams = getSnippetParams(accountPlatformIds);
    if (accountPlatformIds.length) {
      dispatch(
        apiAction({
          method: 'GET',
          path: {
            route: API_PERFORMANCE_ROUTES.PERFORMANCE,
          },
          entity: getSnippetLoadingEntity(id),
          successAction: snippetDataAdded,
          params: { accountId: accountObj.id, ...requestParams },
          config: { addBetterGetParams: false },
          hideError: true, //TODO remove once rate limits for TikTok requests are adjusted
        }),
      );
    }
  };

  const getAccountBiddingChannels = (id) => {
    dispatch(
      apiAction({
        path: {
          route: API_ACCOUNT_ROUTES.GET_ACCOUNT_CHANNELS,
          query: {
            accountId: id,
            bidding: true,
          },
        },
        successAction: channelsLoaded,
        entity: RESOURCES.CHANNEL,
        hideError: true, //TODO remove once rate limits for TikTok requests are adjusted
      }),
    );
  };

  const toggleModuleComments = (id: string, enableModuleComments: boolean) => {
    dispatch(
      apiAction({
        path: {
          route: API_ACCOUNT_ROUTES.TOGGLE_MODULE_COMMENTS,
          variables: {
            accountId: id,
          },
        },
        data: { enableModuleComments },
        method: 'PATCH',
        successAction: accountChanged,
        successToast: {
          message: enableModuleComments ? ACCOUNT_TOASTS.COMMENTS_ENABLED : ACCOUNT_TOASTS.COMMENTS_DISABLED,
        },
      }),
    );
  };

  const toggleEmailNotifications = (id: string, enableEmailNotifications: boolean) => {
    dispatch(
      apiAction({
        path: {
          route: API_ACCOUNT_ROUTES.TOGGLE_EMAIL_NOTIFICATIONS,
          variables: {
            accountId: id,
          },
        },
        data: { enableEmailNotifications },
        method: 'PATCH',
        successAction: accountChanged,
        successToast: {
          message: enableEmailNotifications
            ? ACCOUNT_TOASTS.EMAIL_NOTIFICATIONS_ENABLED
            : ACCOUNT_TOASTS.EMAIL_NOTIFICATIONS_DISABLED,
        },
      }),
    );
  };

  const toggleCreditCardCharges = (id: string, enableCreditCardCharges: boolean) => {
    dispatch(
      apiAction({
        path: {
          route: API_ACCOUNT_ROUTES.TOGGLE_CREDIT_CARD_CHARGES,
          variables: {
            accountId: id,
          },
        },
        data: { enableCreditCardCharges },
        method: 'PATCH',
        successAction: accountChanged,
        successToast: {
          message: enableCreditCardCharges
            ? ACCOUNT_TOASTS.CREDIT_CARD_CHARGES_ENABLED
            : ACCOUNT_TOASTS.CREDIT_CARD_CHARGES_DISABLED,
        },
      }),
    );
  };

  const getAccountPricing = (accountId: string | number) => {
    dispatch(
      apiAction({
        path: {
          route: API_ACCOUNT_ROUTES.ACCOUNT_PRICING,
          variables: {
            accountId,
          },
        },
        successAction: (payload) => accountPricingChanged({ ...payload, data: { ...payload.data, id: +accountId } }),
        entity: RESOURCES.ACCOUNT_PRICING,
      }),
    );
  };

  const updateAccountPricing = (accountId: string, pricing: AccountPricingConfig, onSuccess?: () => void) => {
    dispatch(
      apiAction({
        path: {
          route: API_ACCOUNT_ROUTES.ACCOUNT_PRICING,
          variables: {
            accountId,
          },
        },
        data: { ...pricing },
        method: 'PATCH',
        successAction: (payload) => accountPricingChanged({ ...payload, data: { ...payload.data, id: +accountId } }),
        successToast: {
          message: ACCOUNT_TOASTS.ACCOUNT_PRICING_UPDATED,
        },
        ...(onSuccess ? { onSuccess } : {}),
      }),
    );
  };

  return {
    account,
    accountChannels,
    accounts,
    accountsWithSnippets,
    accountUsers,
    addAccountLogo,
    addAccountBanner,
    brandAssets,
    createAccount,
    deleteAccount,
    deleteBrandAsset,
    deleteBrandAssetInProgress,
    editAccount,
    fetchBrandAssets,
    getAccountBiddingChannels,
    getAllAccounts,
    loading,
    loadingAccountChannels,
    loadingAccountLogo,
    loadingAccountBanner,
    loadingBrandAsset,
    loadingAccountSettings,
    loadingPerformanceIndicator,
    loadingAccountPricing,
    multipleSnippetsLoading,
    removeAccountLogo,
    removeAccountBanner,
    requestSnippets,
    snippetLoading,
    toggleModuleComments,
    toggleEmailNotifications,
    toggleCreditCardCharges,
    uploadBrandAsset,
    updateAccountPricing,
    getAccountPricing,
  };
};
