import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'rebass/styled-components';

import { Box } from 'shared/components/display';

import { BRIEF_COPY } from 'shared/config/copy';
import { TRACKING } from 'shared/config/tracking';

import { LinkCreator } from 'shared/utilities/linkUtility';
import { trackEvent } from 'shared/utilities/trackingUtility';

import briefButtonStyles from './briefButtonStyles';

interface Props {
  organizationId: number;
  accountId: number;
  className?: string;
  buttonClassName?: string;
}
const NewBriefButton: React.FC<Props> = ({ organizationId, accountId, className = '', buttonClassName = '' }) => {
  const navigate = useNavigate();
  return (
    <Box css={briefButtonStyles} className={`new-brief-button ${className}`}>
      <Button
        onClick={() => {
          trackEvent(TRACKING.BRIEF_CREATE);
          const path = LinkCreator.createLink({
            routeKey: 'NEW_BRIEF',
            variables: {
              organizationId,
              accountId,
            },
          });
          navigate(path);
        }}
        className={`new-brief-button__btn ${buttonClassName}`}
      >
        {BRIEF_COPY.BUTTON_CREATE_BRIEF}
      </Button>
    </Box>
  );
};

export default NewBriefButton;
