import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'rebass/styled-components';
import { useFormikContext } from 'formik';

import { fileRemoved } from 'features/media/files.slice';
import { FileCollectionProps } from 'features/submissions/components/organisms/SubmissionModuleArray';
import {
  SubmissionAssetFields,
  SubmissionFormState,
  SubmissionModuleFields,
} from 'features/submissions/components/SubmissionForm/types';
import { useSubmissionThumbnailNotification } from 'features/submissions/hooks/useSubmissionFileNotification';
import { Box, Flex, Image, Text } from 'shared/components/display';
import FieldErrorMessage from 'shared/components/FieldErrorMessage';
import SubmissionAssetUpload from 'shared/components/organisms/SubmissionAssetUpload';

import { SUBMISSION_TOASTS } from 'shared/config/toasts/submissionToasts';
import { useToasts } from 'shared/hooks/useToasts';
import { makeArray } from 'shared/lib/formik';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { ModuleFileType } from 'shared/typings/briefs';
import { BrkfstFile } from 'shared/typings/file';

import styles from './styles';

export type AssetArrayProps = FileCollectionProps & {
  onFilesChanged: (count: number) => void;
  disabled: boolean;
  maxDuration: number;
  moduleFileType: ModuleFileType;
  minDuration: number;
  moduleNum: number;
  allSubmittedFiles: Pick<BrkfstFile, 'id' | 'name' | 'metadata' | 'submissionValidatorFields'>[];
  touched?: boolean;
};

// Used a hook to encapsulate the fact that we're accessing the full formik state
function useAllModules() {
  const formik = useFormikContext<SubmissionFormState>();
  const modules = formik.values.moduleAssets;

  const alreadyIncludesFile = (file: BrkfstFile) => {
    return modules.some((module) => module[SubmissionModuleFields.FILES].some((asset) => asset.id === file.id));
  };

  return {
    alreadyIncludesFile,
  };
}

const SubmissionAssetArray = makeArray<BrkfstFile, AssetArrayProps>(
  ({
    values,
    name,
    push,
    remove,
    replace,
    moduleFileType,
    maxDuration,
    minDuration,
    onFilesChanged,
    disabled,
    moduleNum,
    allSubmittedFiles,
    touched,
  }) => {
    const { alreadyIncludesFile } = useAllModules();
    const { setErrorToast } = useToasts();
    const dispatch = useDispatch();
    //State used to manage files removed during editing that want to be re-added
    const [removedFileIds, setRemovedFileIds] = useState<number[]>([]);

    const addFile = (file: BrkfstFile) => {
      // For updates: use the `moduleSubmissionId` from one of the previous files.
      if (values.length >= 1)
        push({ ...file, moduleSubmissionId: values[0].moduleSubmissionId, minDuration, maxDuration });
      else push({ ...file, minDuration, maxDuration, moduleId: null, moduleSubmissionId: null });

      onFilesChanged(values.length + 1);
    };

    const handleAddFilesFromCollection = (files: BrkfstFile[]) => {
      files.forEach((file) => {
        if (alreadyIncludesFile(file)) {
          setErrorToast({ message: SUBMISSION_TOASTS.SUBMISSION_ASSET_DUPLICATE });
        } else {
          addFile(file);
        }
        if (removedFileIds.includes(file.id)) {
          setRemovedFileIds(
            removedFileIds.filter((removedFileId) => {
              return removedFileId !== file.id;
            }),
          );
        }
      });
    };

    const handleAddDirectlyUploadedFiles = (files: BrkfstFile[]) => {
      files.forEach(addFile);
    };

    const handleUpdate = (file: Pick<BrkfstFile, 'id' | 'thumbnailUrl' | 'thumbnailOffset'>) => {
      if (values.length) {
        const index = values.findIndex((value) => value.id === file.id);
        replace(index, {
          ...values[index],
          ...file,
        });
      }
    };
    useSubmissionThumbnailNotification({ onThumbnailUploaded: handleUpdate });
    const handleRemove = (file: BrkfstFile, index: number) => {
      setRemovedFileIds([...removedFileIds, file.id]);
      remove(index);
      onFilesChanged(values.length - 1);
      dispatch(
        fileRemoved({
          data: { id: file.id, name: file.name },
        }),
      );
    };

    return (
      <Box css={styles} className="asset-input">
        {values?.map((file, index) => (
          // Key must include index to work properly with removing field array rows
          <Fragment key={`${name}-${index}`}>
            <Flex className="asset-input__name-wrapper">
              <Text data-cy={`${name}.${index}__name`} variant={TEXT_VARIANTS.SUBHEADING} className="asset-input__name">
                {file.name}
              </Text>
              <Button
                type="button"
                variant={BUTTON_VARIANTS.TEXT_ONLY}
                className="asset-input__remove-btn"
                disabled={disabled}
                onClick={() => handleRemove(file, index)}
              >
                Remove
              </Button>
            </Flex>
            <Flex className="asset-input__row">
              <Image src={file.thumbnailUrl} alt={file.name} className="asset-input__img" />
              <Box className="asset-input__input-wrapper">
                <FieldErrorMessage name={`${name}[${index}].${SubmissionAssetFields.METADATA}`} touched={touched} />
                <FieldErrorMessage name={`${name}[${index}].${SubmissionAssetFields.SIZE}`} touched={touched} />
              </Box>
            </Flex>
          </Fragment>
        ))}
        <SubmissionAssetUpload
          fileType={moduleFileType}
          getSelectedFiles={handleAddFilesFromCollection}
          getDirectlyUploadedFiles={handleAddDirectlyUploadedFiles}
          isSubmission
          maxAssetDuration={maxDuration}
          minAssetDuration={minDuration}
          disabled={disabled}
          moduleNum={moduleNum}
          allSubmittedFiles={allSubmittedFiles}
          removedFileIds={removedFileIds}
          moduleFiles={values}
        />
        <FieldErrorMessage name={name} touched={touched} />
      </Box>
    );
  },
);

export default SubmissionAssetArray;
