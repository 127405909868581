import { css } from '@emotion/react';

export default css({
  flexDirection: 'column',
  maxWidth: '800px',
  '.brief-modules-form': {
    '&__module': {
      flexDirection: 'column',
      marginBottom: '16px',
    },
    '&__module-name-header': {
      marginTop: '16px',
    },
    '&__module-tags': {
      maxWidth: '800px',
    },
  },
});
