/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
// The 3 lines above are necessary because we're using the css prop on a native html element
import React from 'react';
import cs from 'classnames';

import { Box } from 'shared/components/display';

import InputStyles, { BASE_CLASS, MODIFIERS } from './styles';

type StyleProps = {
  submitted?: boolean;
  noMargin?: boolean;
  noBorder?: boolean;
  width?: number;
  isInvalid?: boolean;
};

type InputProps = JSX.IntrinsicElements['input'] & { dataCy?: string };

/**
 * Based off of UnderlineInput, but with better abstraction.
 * This component handles styles that **only** affect all inputs.
 * E.g. Need to add a character counter style? Okay, put that in TextInput.
 */
const Input = React.forwardRef<HTMLInputElement, StyleProps & InputProps>(
  ({ width, submitted, noMargin, noBorder, className, isInvalid, dataCy, ...props }, ref) => (
    <Box
      {...props}
      dataCy={dataCy}
      ref={ref}
      css={InputStyles}
      className={cs(className, {
        [BASE_CLASS]: true,
        [BASE_CLASS.concat(MODIFIERS.NO_MARGIN)]: noMargin,
        [BASE_CLASS.concat(MODIFIERS.SUBMITTED)]: submitted,
        [BASE_CLASS.concat(MODIFIERS.NO_BORDER)]: noBorder,
        [BASE_CLASS.concat(MODIFIERS.INVALID)]: isInvalid,
      })}
      style={{ width }}
      as="input"
    />
  ),
);

export default Input;
