/// <reference lib="dom" />
import { ThemeProvider as ThemeProviderEmotion } from '@emotion/react';
import React, { useEffect } from 'react';
import { RawIntlProvider } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import GlobalStyles from 'app/GlobalStyles';
import { ThemeProvider } from 'styled-components';
import { useAuth } from 'features/auth/useAuth';
import ToastWrapper from 'features/ui/Toast';
import { initialRefreshCompleted } from 'features/ui/ui.slice';

import 'shared/styles/globalStyles.css';
import { LOCAL_STORAGE } from 'shared/config/storage';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { useInvitationTokenError } from 'shared/hooks/useInvitationTokenError';
import usePageTracking from 'shared/hooks/usePageTracking';
import { useYupValidation } from 'shared/hooks/useYupValidation';
import { intl } from 'shared/lib/intl';
import AuthRoutes from 'shared/router/AuthRoutes';
import theme from 'shared/styles/themes';
import { isTokenExpired } from 'shared/utilities/tokenUtility';

const App = () => {
  const location = useLocation<any>();
  const activating = location.pathname.includes('activate');
  const inviting = location.pathname.includes('invite');
  // to add yup methods
  useYupValidation();

  const { authenticated, currentUser } = useCurrentUser();
  useInvitationTokenError();

  const { getUserByToken } = useAuth();

  const token = localStorage.getItem(LOCAL_STORAGE.TOKEN);
  const dispatch = useDispatch();

  usePageTracking(currentUser);

  useEffect(() => {
    if (!activating && !inviting && token && !isTokenExpired(token) && !authenticated) {
      getUserByToken(token);
    }
    dispatch(initialRefreshCompleted());
  }, [activating, authenticated, dispatch, getUserByToken, inviting, token]);

  useEffect(() => {
    document.documentElement.style.height = `${window.innerHeight}px`;
    const root = document.querySelector<Element & { style: any }>('#root');
    if (root) {
      root.style.height = `${window.innerHeight}px`;
    }
  }, [window.innerHeight]);

  return (
    <div className="App">
      <GlobalStyles />
      <ThemeProviderEmotion theme={theme}>
        <ThemeProvider theme={theme}>
          <RawIntlProvider value={intl}>
            <ToastWrapper />
            <AuthRoutes />
          </RawIntlProvider>
        </ThemeProvider>
      </ThemeProviderEmotion>
    </div>
  );
};

export default App;
