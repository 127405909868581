import React from 'react';
import { Field } from 'formik';

import * as yup from 'yup';

import { Box } from 'shared/components/display';
import SocialMediaHandleInput from 'shared/components/SocialMediaHandleInput/SocialMediaHandleInput';
import TextInput from 'shared/components/TextInput';
import { YUP_FIELDS } from 'shared/utilities/yupFieldUtility';

interface Props {}

export const PortfolioOnboardingStepFields: React.FC<Props> = () => {
  return (
    <Box className="portfolio-step">
      <Field
        name={PORTFOLIO_FIELDNAMES.PORTFOLIO_LINK}
        component={TextInput}
        label="Portfolio Link"
        placeholder="Portfolio Link"
      />
      <Field
        name={PORTFOLIO_FIELDNAMES.INSTAGRAM_HANDLE}
        label="Instagram Handle"
        placeholder="Instagram Handle"
        component={SocialMediaHandleInput}
        dataCy="instagram-handle"
      />
      <Field
        name={PORTFOLIO_FIELDNAMES.TIKTOK_HANDLE}
        label="TikTok Handle"
        placeholder="TikTok Handle"
        component={SocialMediaHandleInput}
        dataCy="tiktok-handle"
      />
    </Box>
  );
};

export const PORTFOLIO_FIELDNAMES = {
  PORTFOLIO_LINK: 'portfolioLink',
  TIKTOK_HANDLE: 'tiktokHandle',
  INSTAGRAM_HANDLE: 'instagramHandle',
};

export const getPortfolioSchema = () =>
  yup.object({
    [PORTFOLIO_FIELDNAMES.PORTFOLIO_LINK]: YUP_FIELDS.PORTFOLIO_LINK_OPTIONAL,
    [PORTFOLIO_FIELDNAMES.TIKTOK_HANDLE]: yup.string().nullable().tiktokHandle(),
    [PORTFOLIO_FIELDNAMES.INSTAGRAM_HANDLE]: yup.string().nullable().instagramHandle(),
  });

export const portfolioInitialValues = {
  [PORTFOLIO_FIELDNAMES.PORTFOLIO_LINK]: '',
  [PORTFOLIO_FIELDNAMES.TIKTOK_HANDLE]: '',
  [PORTFOLIO_FIELDNAMES.INSTAGRAM_HANDLE]: '',
};
