import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default (extraStyles?: Record<string, string>) =>
  css({
    '&.notification-banner': {
      borderRadius: '4px',
      alignItems: 'center',
      padding: theme.space[2],
      ...extraStyles,
    },
    '.notification-banner': {
      '&__icon': {
        borderRadius: '50%',
        marginRight: theme.space[4],
        marginLeft: theme.space[2],
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
        fontSize: theme.fontSizes[2],
      },
      '&__text': {
        margin: 0,
        whiteSpace: 'pre-line',
      },
    },
    '&.notification-banner--info': {
      backgroundColor: COLORS.PRIMARY50,

      '& .notification-banner__icon': {
        backgroundColor: COLORS.PRIMARY500,
        color: COLORS.WHITE,
        width: '25px',
        height: '25px',
      },
      '& .notification-banner__text': {
        color: COLORS.PRIMARY500,
      },
      '& .notification-banner__info-icon': {},
    },
    '&.notification-banner--info-alt': {
      backgroundColor: COLORS.PRIMARY50,

      '& .notification-banner__icon': {
        width: '30px',
        height: '30px',
      },
      '& .notification-banner__text': {
        color: COLORS.PRIMARY500,
      },
      '& .notification-banner__info-alt-icon': {
        color: COLORS.PRIMARY500,
      },
    },
    '&.notification-banner--warning': {
      backgroundColor: COLORS.ORANGE50,
      '& .notification-banner__icon': {
        color: COLORS.ORANGE500,
      },
      '& .notification-banner__text': {
        color: COLORS.ORANGE800,
      },
      '& .notification-banner__warning-icon': {},
    },
  });
