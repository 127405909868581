import React, { Fragment, useState } from 'react';
import { Field } from 'formik';
import shortid from 'shortid';

import BriefDuration from 'features/briefs/components/molecules/BriefDuration';
import { Flex } from 'shared/components/display';
import FieldArrayTabBar from 'shared/components/molecules/FieldArrayTabBar';
import Heading from 'shared/components/molecules/Heading';
import RadioGroupInput from 'shared/components/molecules/RadioGroupInput';
import { CategoryTagInput } from 'shared/components/molecules/TagInput';
import { RICHTEXT_DEFAULT_VALUE, RichtextInput } from 'shared/components/Richtext';
import TextInput from 'shared/components/TextInput';

import { BRIEF_COPY } from 'shared/config/copy';
import {
  AD_SUPPORTED_GIF_FORMATS,
  AD_SUPPORTED_IMAGE_FORMATS,
  AD_SUPPORTED_VIDEO_FORMATS,
  formatFileTypes,
} from 'shared/config/fileFormats';
import { RECOMMENDED_SLIDER_MAX, RECOMMENDED_SLIDER_MIN } from 'shared/config/validations';
import { makeArray } from 'shared/lib/formik';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { ModuleFileType } from 'shared/typings/briefs';

import { BriefModuleValues } from '../../config';
import ModuleScenesForm from '../ModuleScenesForm';

import styles from './styles';

export const MAX_MODULES = 10;
const MIN_MODULES = 1;

const NAME = 'name';
const CONTENT = 'content';
const TAGS = 'tags';
const DURATION_RANGE = 'durationRange';
const FILE_TYPE = 'fileType';

export const moduleDefaults: BriefModuleValues = {
  content: RICHTEXT_DEFAULT_VALUE,
  tags: [],
  name: '',
  fileType: 'video',
  durationRange: [RECOMMENDED_SLIDER_MIN, RECOMMENDED_SLIDER_MAX],
  scenes: [],
  id: '',
};

const ACCEPTED_MEDIA_EXAMPLES = {
  video: formatFileTypes(AD_SUPPORTED_VIDEO_FORMATS),
  gif: formatFileTypes(AD_SUPPORTED_GIF_FORMATS),
  image: formatFileTypes(AD_SUPPORTED_IMAGE_FORMATS),
};

export const FILE_TYPE_OPTIONS: { value: ModuleFileType; label: string }[] = [
  { value: 'video', label: `Video (${ACCEPTED_MEDIA_EXAMPLES.video})` },
  { value: 'gif', label: `GIF (${ACCEPTED_MEDIA_EXAMPLES.gif})` },
  { value: 'image', label: `Image (${ACCEPTED_MEDIA_EXAMPLES.image})` },
];

interface BriefModulesFormProps {
  isDraftState: boolean;
}

export const BriefModulesForm = makeArray<BriefModuleValues, BriefModulesFormProps>(
  ({ values: modules, name, isDraftState, push, remove, move }) => {
    const [selected, setSelected] = useState<number>(0);

    const createModule = (): BriefModuleValues => ({
      ...moduleDefaults,
      id: shortid.generate(),
    });

    const handleAdd = () => {
      push(createModule());
      setSelected(modules.length);
    };

    return (
      <Flex css={styles} className="brief-modules-form">
        <FieldArrayTabBar
          name={name}
          canRemove={isDraftState && modules.length > MIN_MODULES}
          canAdd={isDraftState && modules.length < MAX_MODULES}
          onRemove={remove}
          onAdd={handleAdd}
          defaultTabText={BRIEF_COPY.FORM_MODULE_DISPLAY}
          setSelectedTab={setSelected}
          selectedTab={selected}
          move={move}
        />
        {modules?.map((mod, idx) =>
          idx === selected ? (
            <Flex className="brief-modules-form__module" key={`${mod.id}-${idx}`}>
              <Heading
                text={`${BRIEF_COPY.LABEL_NAME_MODULE}*`}
                headerText={BRIEF_COPY.LABEL_NAME_MODULE}
                descriptionText={BRIEF_COPY.FORM_INFO_MODULE_NAME}
                className="brief-modules-form__module-name-header"
              />
              <Field
                name={`${name}[${idx}].${NAME}`}
                key={`${name}[${idx}].${NAME}`}
                component={TextInput}
                maxLength={50}
                placeholder={BRIEF_COPY.PLACEHOLDER_MODULE_FORM}
                label={BRIEF_COPY.LABEL_NAME_MODULE + '*'}
                dataCy="brief-module__name"
                readableWidth
              />
              <Heading
                text={`${BRIEF_COPY.LABEL_DESCRIPTION_MODULE}*`}
                headerText={BRIEF_COPY.LABEL_DESCRIPTION_MODULE}
                descriptionText={BRIEF_COPY.FORM_INFO_MODULE_DESCRIPTION}
                className="brief-multi-page-form__header"
              />
              <Field
                name={`${name}[${idx}].${CONTENT}`}
                key={`${name}[${idx}].${CONTENT}`}
                component={RichtextInput}
                placeholder={BRIEF_COPY.PLACEHOLDER_MODULE_FORM}
                dataCy="brief-module__content"
                readableWidth
              />
              <Field
                name={`${name}[${idx}].${TAGS}`}
                component={CategoryTagInput}
                className="brief-modules-form__module-tags"
                placeholder={BRIEF_COPY.FORM_PLACEHOLDER_MODULE_TAGS}
                categoryRequired
              />
              <Heading
                text={BRIEF_COPY.FORM_HEADING_FILE_TYPE}
                variant={TEXT_VARIANTS.H5}
                className="brief-form__section-header"
              />
              <Field
                name={`${name}[${idx}].${FILE_TYPE}`}
                component={RadioGroupInput}
                options={FILE_TYPE_OPTIONS}
                disabled={!isDraftState}
              />
              {mod.fileType !== 'image' && (
                <Field name={`${name}[${idx}].${DURATION_RANGE}`} component={BriefDuration} />
              )}
              <ModuleScenesForm moduleIndex={idx} name={`${name}[${idx}].scenes`} />
            </Flex>
          ) : (
            <Fragment key={`${mod.id}-${idx}`}></Fragment>
          ),
        )}
      </Flex>
    );
  },
);
