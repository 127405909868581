import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import cs from 'classnames';

import { CONSTRAINT_LIMITS } from 'shared/config/validations';
import { decodeHtml } from 'shared/utilities/domUtility';

export const EMPTY_TAG_VALUE = '-';

/** @deprecated
 * TODO remove once replaced everywhere
 */
const TagComponent: React.FC<any> = ({ props: [tagData] }) => {
  const { value, category, class: className } = tagData;
  const pattern = new RegExp(`^.{1,${CONSTRAINT_LIMITS.TAG_MAX_LENGTH}}$`);
  const tagTooLong = !pattern.test(decodeHtml(value) || '');

  return (
    <tag
      title={value}
      contentEditable={false}
      spellCheck={false}
      className={cs('tagify__tag', {
        [className]: !!className,
        'tagify__tag--invalid': tagTooLong || !category || value === EMPTY_TAG_VALUE,
      })}
      data-cy={`tagify__tag--${category}:${value}`}
    >
      <x
        aria-label="remove tag"
        title="remove tag"
        className="tagify__tag__removeBtn"
        data-cy="tagify__tag__removeBtn"
      />
      <span
        className={cs({
          'tagify__tag-category': !!category,
        })}
      >
        {category?.toUpperCase()}
      </span>
      <span className="tagify__tag-text">{value}</span>
    </tag>
  );
};

const Tag = (...props) => renderToStaticMarkup(<TagComponent props={props} />);

export default Tag;
