import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import Modal from 'shared/components/molecules/Modal';
import Prompt from 'shared/components/molecules/Prompt';
import { SIZING } from 'shared/config/formatting';
import { isEmpty } from 'lodash';
import ActionButton from '../ActionButton';

interface Props<Values> {
  buttonText: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
  disabled?: boolean;
  modalSize?: string;
  onConfirm: (values: Values) => void;
  text: string;
  title?: string;
  validate?: boolean;
  type?: 'button' | 'submit';
  loading?: boolean;
  className?: string;
  onClick?: () => void;
  variant?: string;
}
function FormPromptButton<Values>({
  disabled,
  buttonText,
  confirmButtonText = 'Confirm',
  cancelButtonText = 'Cancel',
  onConfirm,
  text,
  modalSize = SIZING.SMALL,
  title = '',
  validate,
  type = 'button',
  loading = false,
  className,
  onClick: handleClick,
  variant,
}: React.PropsWithChildren<Props<Values>>): JSX.Element {
  const { isSubmitting, validateForm, values } = useFormikContext<Values>();
  const [isOpen, setIsOpen] = useState(false);
  const isDisabled = disabled !== null ? disabled : isSubmitting;

  const toggleModal = () => {
    setIsOpen((prev) => !prev);
  };

  const onClick = () => {
    if (handleClick) handleClick();
    if (validate) {
      validateForm().then((errors) => {
        const errorFree = isEmpty(errors);

        if (errorFree) {
          toggleModal();
        }
      });
    } else {
      toggleModal();
    }
  };

  return (
    <>
      <ActionButton
        variant={variant}
        type={type}
        onClick={onClick}
        loading={loading}
        disabled={isDisabled}
        className={className}
      >
        {buttonText}
      </ActionButton>
      <Modal
        isOpen={isOpen}
        modalSize={modalSize}
        title={title}
        onRequestClose={toggleModal}
        component={
          <Prompt
            onRequestClose={toggleModal}
            confirmButtonText={confirmButtonText}
            cancelButtonText={cancelButtonText}
            confirmCallback={() => onConfirm(values)}
            text={text}
          />
        }
        ariaRole="alertdialog"
      />
    </>
  );
}

export default FormPromptButton;
