import React, { useState } from 'react';
import { capitalize } from 'lodash';

import { Box } from 'shared/components/display';
import { ArrayErrorMessage } from 'shared/components/FieldErrorMessage';
import Heading from 'shared/components/molecules/Heading';
import { SegmentedButton, SegmentOption } from 'shared/components/molecules/SegmentedButton';
import Range from 'shared/components/Range';
import RangeSlider from 'shared/components/organisms/RangeSlider';

import { BRIEF_COPY } from 'shared/config/copy';
import {
  FACEBOOK_VIDEO_MAX_DURATION,
  FACEBOOK_VIDEO_MIN_DURATION,
  RECOMMENDED_SLIDER_MAX,
  RECOMMENDED_SLIDER_MIN,
} from 'shared/config/validations';
import { Input } from 'shared/lib/formik';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { briefDurationStyles } from './styles';

enum DurationType {
  RECOMMENDED = 'recommended',
  CUSTOM = 'custom',
}

type Value = number[];

const DurationTypeSegments: SegmentOption<DurationType>[] = [
  {
    text: capitalize(DurationType.RECOMMENDED),
    value: DurationType.RECOMMENDED,
  },
  {
    text: capitalize(DurationType.CUSTOM),
    value: DurationType.CUSTOM,
  },
];

function getInputType(inputValue: Value) {
  const isValid = Boolean(inputValue?.length);
  if (!isValid) return DurationType.RECOMMENDED;
  const [min, max] = inputValue;
  if (max > RECOMMENDED_SLIDER_MAX || max < min) {
    return DurationType.CUSTOM;
  }
  return DurationType.RECOMMENDED;
}

const BriefDuration: Input<Value> = ({ field: { value, name, onChange, onBlur } }) => {
  const [durationType, setDurationType] = useState<DurationType>(getInputType(value));
  const [customValue, setCustomValue] = useState<Value>(value);
  const [recommendedValue, setRecommendedValue] = useState<Value>(
    durationType === DurationType.RECOMMENDED ? value : [RECOMMENDED_SLIDER_MIN, RECOMMENDED_SLIDER_MAX],
  );

  const handleChange = (type: DurationType) => (value: Value) => {
    const setState = durationType === DurationType.CUSTOM ? setCustomValue : setRecommendedValue;
    setState(value);
    onChange({ target: { name, value } });
    onBlur({ target: { name } });
  };

  const onTypeChange = (type: DurationType) => {
    setDurationType(type);
    if (type === DurationType.CUSTOM) {
      onChange({ target: { name, value: customValue } });
    } else {
      onChange({ target: { name, value: recommendedValue } });
    }
  };

  return (
    <Box css={briefDurationStyles} className="brief-duration">
      <Heading
        text={BRIEF_COPY.FORM_HEADING_DURATION}
        descriptionText={BRIEF_COPY.FORM_TOOLTIP_DURATION}
        variant={TEXT_VARIANTS.H5}
        className="brief-duration__header"
      />
      <SegmentedButton<DurationType>
        onChanged={(segment) => {
          onTypeChange(segment.value);
        }}
        options={DurationTypeSegments}
        value={durationType}
      />
      <Box className="brief-duration__wrapper">
        {durationType === DurationType.CUSTOM ? (
          <Range
            onChange={handleChange(DurationType.CUSTOM)}
            values={customValue}
            min={FACEBOOK_VIDEO_MIN_DURATION}
            max={FACEBOOK_VIDEO_MAX_DURATION}
          />
        ) : (
          <RangeSlider
            onChange={handleChange(DurationType.RECOMMENDED)}
            values={recommendedValue}
            min={RECOMMENDED_SLIDER_MIN}
            max={RECOMMENDED_SLIDER_MAX}
          />
        )}
      </Box>
      <ArrayErrorMessage name={name} />
    </Box>
  );
};

export default BriefDuration;
