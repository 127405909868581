export const API_BRIEF_ROUTES = Object.freeze({
  ACCEPT_INVITATION: '/brief/:briefId/accept/:creatorId',
  ALL_CREATORS: '/brief/:briefId/all-creators',
  APPLY_TO_BRIEF: '/brief/:briefId/apply',
  APPROVE_CREATOR_INVOICE_DECLINED: '/brief/:briefId/approve/:creatorId/declined',
  APPROVE_CREATOR_INVOICE_INVITE: '/brief/:briefId/approve/invoice',
  APPROVE_CREATOR_INVOICE_PENDING: '/brief/:briefId/approve/:creatorId/pending',
  APPROVE_CREATOR_INVOICE: '/brief/:briefId/approve/:creatorId/invoice',
  APPROVE_CREATOR: '/brief/:briefId/approve/:creatorId',
  BRIEF: '/brief/:briefId',
  BRIEFS: '/brief',
  CHECKOUT: '/brief/checkout',
  CLOSE_BRIEF: '/brief/:briefId/close',
  GET_BRIEF_CREATORS_SHIPPING_INFO: '/brief/:briefId/creators-shipping',
  INVITE_CREATORS: '/brief/:briefId/invite',
  PAGINATED_CREATORS: '/brief/:briefId/creators',
  PAUSE_BRIEF: '/brief/:briefId/pause',
  PUBLISH_BRIEF: '/brief/:briefId/publish',
  REJECT_CREATOR: '/brief/:briefId/reject/:creatorId',
  REOPEN_BRIEF: '/brief/:briefId/reopen',
  RESUME_BRIEF: '/brief/:briefId/resume',
  UNRELATED_CREATORS: '/brief/:briefId/unrelated-creators',
  CREATOR_BRIEFS: '/brief/:userId/creator-briefs',
});
