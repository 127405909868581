import React, { useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Button } from 'rebass';
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import * as yup from 'yup';

import { CREATOR_ACCOUNT_SETTINGS_COPY } from 'features/users/pages/CreatorAccountSettings/copy';
import { Box, Flex, Text } from 'shared/components/display';
import FormButton from 'shared/components/FormButton';
import CategoryTags from 'shared/components/molecules/CategoryTags';

import { BUTTON_VARIANTS } from 'shared/styles/button';
import COLORS from 'shared/styles/colors';
import { breakpoints } from 'shared/styles/styleFunctions';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { BrkfstUser, CreatorUser } from 'shared/typings/user';
import { capitalizeWithoutConjunctions } from 'shared/utilities/stringUtility';

import {
  AGE_FIELDNAMES,
  AgeFormFields,
  ageSchema,
  ETHNICITY_FIELDNAMES,
  EthnicityFormFields,
  ethnicitySchema,
  GENDER_FIELDNAMES,
  GenderFormFields,
  genderOtherOption,
  genderSchema,
} from '../../CreatorOnboardingStepsFields';
import { HeightFormFields, heightSchema } from '../../CreatorOnboardingStepsFields/HeightFormFields';
import {
  LANGUAGE_FIELDNAMES,
  LanguageFormFields,
  languageSchema,
} from '../../CreatorOnboardingStepsFields/LanguageFormFields';

import { FIELDNAMES, getFieldsToReset, getInitialValues, ProfileInfoSections, SECTIONS } from './config';
import styles from './styles';

interface Props {
  user: BrkfstUser;
  tags: { [category: string]: { label: string; value: string }[] };
  onSubmit: (values: any, activeSubSection?: ProfileInfoSections | null) => void;
  onNavigateBack?: () => void;
}

const ProfileInfoForm: React.FC<Props> = ({ user, tags, onSubmit, onNavigateBack }) => {
  const [activeForm, setActiveForm] = useState<null | ProfileInfoSections>(null);
  const creatorUser = user as CreatorUser;
  const isMobile = useMediaQuery({
    maxWidth: breakpoints.md - 1,
  });
  const initialValues = getInitialValues(creatorUser, tags);

  const onCancel = (setValues: (values: any) => void, currentValues: any) => {
    const fieldsToReset = getFieldsToReset(activeForm, initialValues);
    setValues({ ...currentValues, ...fieldsToReset });
  };

  const getFormDisplayValue = (formName: ProfileInfoSections, values) => {
    switch (formName) {
      case 'Date of Birth':
        return (
          <Text variant={TEXT_VARIANTS.BODY_SM} className="profile-info-form__btn-value">
            {values[AGE_FIELDNAMES.DOB] ? values[AGE_FIELDNAMES.DOB] : '-'}
          </Text>
        );
      case 'gender':
        const selectedGender = values[GENDER_FIELDNAMES.GENDER]?.find(
          ({ selected, label }) => selected && label !== 'Other',
        )?.value;
        return (
          <Text variant={TEXT_VARIANTS.BODY_SM} className="profile-info-form__btn-value">
            {selectedGender && selectedGender !== genderOtherOption.value ? selectedGender?.toUpperCase() : '-'}
          </Text>
        );
      case 'height':
        return (
          <Text variant={TEXT_VARIANTS.BODY_SM} className="profile-info-form__btn-value">
            {isEmpty(values[FIELDNAMES.HEIGHT]) ? '-' : values[FIELDNAMES.HEIGHT].label}
          </Text>
        );
      case 'ethnicity':
        const selectedEthnicities = values[ETHNICITY_FIELDNAMES.ETHNICITY].filter(
          ({ selected, label }) => selected && label !== 'Other',
        );

        return selectedEthnicities?.length > 0 ? (
          <CategoryTags
            category="ethnicity"
            theme="definition-list-category"
            className="profile-info-form__display-tags"
            values={selectedEthnicities}
            uppercaseCategory
          />
        ) : (
          <Box>
            <Text
              variant={TEXT_VARIANTS.SUBHEADING_SM}
              color={COLORS.NEUTRAL400}
              className="profile-info-form__btn-name"
            >
              {capitalizeWithoutConjunctions(FIELDNAMES.ETHNICITY)}
            </Text>
            <Text className="profile-info-form__btn-value">-</Text>
          </Box>
        );
      case 'language':
        const selectedLanguages = values[LANGUAGE_FIELDNAMES.LANGUAGE].filter(
          ({ selected, label }) => selected && label !== 'Other',
        );
        return selectedLanguages?.length ? (
          <CategoryTags
            category="language"
            theme="definition-list-category"
            className="profile-info-form__display-tags"
            values={selectedLanguages}
            uppercaseCategory
          />
        ) : (
          <Box>
            <Text
              variant={TEXT_VARIANTS.SUBHEADING_SM}
              color={COLORS.NEUTRAL400}
              className="profile-info-form__btn-name"
            >
              {capitalizeWithoutConjunctions(FIELDNAMES.LANGUAGE)}
            </Text>
            <Text className="profile-info-form__btn-value">-</Text>
          </Box>
        );
      // Note is not a form field
      case 'Note:':
        const hasNote =
          values[ETHNICITY_FIELDNAMES.ETHNICITY_OTHER] ||
          values[LANGUAGE_FIELDNAMES.LANGUAGE_OTHER] ||
          values[GENDER_FIELDNAMES.GENDER_OTHER];
        const ethnicity = values[ETHNICITY_FIELDNAMES.ETHNICITY_OTHER]
          ? `Other ethnicity: ${values[ETHNICITY_FIELDNAMES.ETHNICITY_OTHER]}`
          : '';
        const language = values[LANGUAGE_FIELDNAMES.LANGUAGE_OTHER]
          ? `Other language: ${values[LANGUAGE_FIELDNAMES.LANGUAGE_OTHER]}`
          : '';
        const gender = values[GENDER_FIELDNAMES.GENDER_OTHER]
          ? `Other gender: ${values[GENDER_FIELDNAMES.GENDER_OTHER]}`
          : '';

        return hasNote ? (
          <Flex className="profile-info-form__note">
            <Text variant={TEXT_VARIANTS.BODY_SM} className="profile-info-form__btn-value">
              {language}
            </Text>
            <Text variant={TEXT_VARIANTS.BODY_SM} className="profile-info-form__btn-value">
              {gender}
            </Text>
            <Text variant={TEXT_VARIANTS.BODY_SM} className="profile-info-form__btn-value">
              {ethnicity}
            </Text>
          </Flex>
        ) : (
          <Text className="profile-info-form__btn-value">-</Text>
        );
    }
  };

  const onGoBack = (setValues: (values: any) => void, currentValues: any) => {
    onCancel(setValues, currentValues);
    setActiveForm(null);
  };

  const validationSchema = useMemo(() => {
    const schemas = {
      [FIELDNAMES.BIRTHDATE]: ageSchema,
      [FIELDNAMES.GENDER]: genderSchema,
      [FIELDNAMES.HEIGHT]: heightSchema,
      [FIELDNAMES.ETHNICITY]: ethnicitySchema,
      [FIELDNAMES.LANGUAGE]: languageSchema,
    };

    return activeForm ? schemas[activeForm] : yup.object();
  }, [activeForm]);

  return (
    <Box css={styles} className="profile-info-form">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => onSubmit(values, activeForm)}
      >
        {({ setValues, values, errors }) => (
          <Form
            className={cs({
              'profile-info-form__form--active': Boolean(activeForm),
            })}
          >
            {activeForm && (
              <Flex className="profile-info-form__form-header-wrapper">
                <FontAwesomeIcon
                  icon={faAngleLeft}
                  className="profile-info-form__back-btn"
                  fixedWidth
                  color={COLORS.NEUTRAL600}
                  onClick={() => onGoBack(setValues, values)}
                />
                <Text className="profile-info-form__form-header" variant={TEXT_VARIANTS.SUBHEADING}>
                  {capitalizeWithoutConjunctions(activeForm)}
                </Text>
              </Flex>
            )}
            {activeForm === 'Date of Birth' && (
              <>
                <Text className="profile-info-form__label" variant={TEXT_VARIANTS.H6}>
                  {CREATOR_ACCOUNT_SETTINGS_COPY.SUBSECTION_TITLE_AGE}
                </Text>
                <AgeFormFields className="profile-info-form__subheader" />
              </>
            )}
            {activeForm === 'gender' && (
              <>
                <Flex className="profile-info-form__label-optional-wrapper">
                  <Text className="profile-info-form__label" variant={TEXT_VARIANTS.H6}>
                    {CREATOR_ACCOUNT_SETTINGS_COPY.SUBSECTION_TITLE_GENDER}
                  </Text>
                </Flex>
                <GenderFormFields />
              </>
            )}
            {activeForm === 'height' && (
              <>
                <Flex className="profile-info-form__label-optional-wrapper">
                  <Text className="profile-info-form__label" variant={TEXT_VARIANTS.H6}>
                    {CREATOR_ACCOUNT_SETTINGS_COPY.SUBSECTION_TITLE_HEIGHT}
                  </Text>
                  <Text className="profile-info-form__optional">Optional</Text>
                </Flex>
                <HeightFormFields />
              </>
            )}
            {activeForm === 'ethnicity' && (
              <>
                <Flex className="profile-info-form__label-optional-wrapper">
                  <Text className="profile-info-form__label" variant={TEXT_VARIANTS.H6}>
                    {CREATOR_ACCOUNT_SETTINGS_COPY.SUBSECTION_TITLE_ETHNICITY}
                  </Text>
                  <Text className="profile-info-form__optional">Optional</Text>
                </Flex>
                <EthnicityFormFields className="profile-info-form__subheader" />
              </>
            )}
            {activeForm === 'language' && (
              <>
                <Flex className="profile-info-form__label-optional-wrapper">
                  <Text className="profile-info-form__label" variant={TEXT_VARIANTS.H6}>
                    {CREATOR_ACCOUNT_SETTINGS_COPY.SUBSECTION_TITLE_LANGUAGE}
                  </Text>
                  <Text className="profile-info-form__optional">Optional</Text>
                </Flex>
                <LanguageFormFields className="profile-info-form__subheader" />
              </>
            )}
            {activeForm === null && (
              <>
                {isMobile && (
                  <Flex className="profile-info-form__form-header-wrapper">
                    <FontAwesomeIcon
                      icon={faAngleLeft}
                      className="profile-info-form__back-btn"
                      fixedWidth
                      color={COLORS.NEUTRAL600}
                      onClick={onNavigateBack}
                    />
                    <Text className="profile-info-form__form-header" variant={TEXT_VARIANTS.SUBHEADING}>
                      Profile Info
                    </Text>
                  </Flex>
                )}
                {SECTIONS.map(({ name, isTag }) => (
                  <Box
                    key={name}
                    onClick={() => {
                      name !== 'Note:' ? setActiveForm(name) : null;
                    }}
                    className={classNames('profile-info-form__btn', {
                      'profile-info-form__btn--active': activeForm === name,
                      'profile-info-form__btn--readonly': name === 'Note:',
                    })}
                  >
                    <Flex className="profile-info-form__btn-content">
                      <Flex className="profile-info-form__btn-text-wrapper">
                        {/* The label for tag fields is displayed by CategoryTags component */}
                        {!isTag ? (
                          <Text
                            variant={TEXT_VARIANTS.SUBHEADING_SM}
                            color={COLORS.NEUTRAL400}
                            className="profile-info-form__btn-name"
                          >
                            {capitalizeWithoutConjunctions(name)}
                          </Text>
                        ) : (
                          <></>
                        )}
                        {getFormDisplayValue(name, values)}
                      </Flex>

                      {name !== 'Note:' && (
                        <FontAwesomeIcon icon={faAngleRight} className="profile-info-form__right-icon" fixedWidth />
                      )}
                    </Flex>
                  </Box>
                ))}
              </>
            )}
            {activeForm && (
              <Flex className="profile-info-form__btn-wrapper">
                <Button
                  variant={BUTTON_VARIANTS.OPTION}
                  className="profile-info-form__cancel-btn"
                  onClick={() => {
                    onCancel(setValues, values);
                  }}
                  type="button"
                >
                  Cancel
                </Button>
                <FormButton type="submit" disableUntilTouched>
                  Save Changes
                </FormButton>
              </Flex>
            )}
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default ProfileInfoForm;
