import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default css({
  '&.brief-fields': {},
  '.brief-fields': {
    '&__hr': {
      marginTop: '1rem',
    },
    '&__internal-note': {
      border: `1px solid ${COLORS.NEUTRAL200}`,
      borderRadius: '10px',
      padding: theme.space[3],
      height: '70px',
    },
    '&__header': {
      fontSize: '24px',
      marginBottom: theme.space[4],
    },
    '&__module-details': {
      borderBottomLeftRadius: '10px',
      borderBottomRightRadius: '10px',
      paddingBottom: theme.space[2],
    },
    '&__module-label.definition-list__label': {
      backgroundColor: COLORS.ORANGE300,
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
      paddingLeft: theme.space[3],
    },
    '&__module-details:nth-of-type(odd)': {
      backgroundColor: `${COLORS.NEUTRAL100}90`,
    },
    '&__module-details:nth-of-type(even)': {
      backgroundColor: `${COLORS.PRIMARY200}90`,
    },
    '&__link-wrapper': {
      flexDirection: 'column',
      gap: theme.space[2],
    },
    '&__link-icon': {
      marginLeft: theme.space[2],
    },
    '&__link': {
      display: 'flex',
      alignItems: 'center',
    },
  },
});
