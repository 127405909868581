import React, { Suspense, useEffect, useMemo } from 'react';
import { Navigate, Route, Routes, useLocation, useMatch, useNavigate, useParams } from 'react-router-dom';

import { useAccounts } from 'features/accounts/hooks/useAccounts';
import { useBriefs } from 'features/briefs/hooks/useBriefs';
import BriefApprovalCheckoutPage from 'features/briefs/pages/BriefApprovalCheckout/BriefApprovalCheckoutPage';
import BriefInviteApprovalCheckoutPage from 'features/briefs/pages/BriefInviteApprovalCheckout/BriefInviteApprovalCheckoutPage';
import MarketerBriefsHome from 'features/briefs/pages/MarketerBriefsHome';
import SubmissionCheckout from 'features/submissions/components/organisms/SubmissionCheckout';
import { CenteredSpinnerContainer } from 'features/ui/CenteredSpinner';
import LazySpinner from 'shared/components/molecules/LazySpinner';

import { PERMISSIONS_MAP } from 'shared/config/permissions';
import BRAND_BRIEF_ROUTES from 'shared/config/routes/brands/briefRoutes';
import { AccountRouteParams } from 'shared/config/routes/brands/matchTypes';
import { usePermissions } from 'shared/hooks/usePermissions';
import lazyWithRetry from 'shared/lib/lazyWithRetry';
import { LinkCreator } from 'shared/utilities/linkUtility';

const CheckoutComplete = lazyWithRetry(() => import('shared/components/molecules/CheckoutComplete'));
const BriefCheckoutPage = lazyWithRetry(() => import('features/briefs/pages/BriefCheckoutPage'));
const CreateUpdateBrief = lazyWithRetry(() => import('features/briefs/pages/CreateUpdateBrief/CreateUpdateBrief'));
const IndividualBriefPage = lazyWithRetry(() => import('features/briefs/pages/MarketerIndividualBriefPage'));
const BrandSubmissionPage = lazyWithRetry(() => import('features/submissions/pages/BrandSubmissionPage'));

type BrandBriefRouteParams = {
  organizationId: string;
  accountId: string;
  briefId: string;
};

const BrandBriefRoutes = () => {
  const location = useLocation();
  const path = location.pathname.match(/briefs\/\d+/)
    ? `${BRAND_BRIEF_ROUTES.VIEW_INDIVIDUAL_BRIEF}/*`
    : `${BRAND_BRIEF_ROUTES.BRIEF_INDEX}/*`;
  const match = useMatch<keyof BrandBriefRouteParams, typeof path>(path);
  const briefId = match!.params.briefId || '';
  const organizationId = match!.params.organizationId!;
  const accountId = match!.params.accountId!;
  const navigate = useNavigate();
  const params = useParams<AccountRouteParams>();
  const { isAuthorized } = usePermissions();
  const { getAccountPricing, account, loadingAccountPricing } = useAccounts({ accountId: params.accountId });
  const briefPagePath = useMemo(() => {
    return LinkCreator.createLink({
      routeKey: 'BRIEF_INDEX',
      variables: {
        organizationId,
        accountId,
      },
    });
  }, [organizationId, accountId]);
  // if we're creating a new brief the briefId will be 'new'
  const createNew = briefId === 'new';

  const canCreateEditDraft = isAuthorized(+organizationId, PERMISSIONS_MAP.CREATE_EDIT_BRIEF_DRAFT.API);
  const canApproveCreators = isAuthorized(+organizationId, PERMISSIONS_MAP.APPROVE_CREATORS.API);
  const canPublishEditBrief = isAuthorized(+organizationId, PERMISSIONS_MAP.PUBLISH_EDIT_BRIEF.API);

  const { brief: storedBrief, fetchBrief, loading } = useBriefs(briefId);
  const { pricing } = account;

  useEffect(() => {
    if (!createNew && !storedBrief && briefId.length > 0) {
      fetchBrief(briefId);
    }
  }, [briefId, storedBrief]);

  useEffect(() => {
    if (storedBrief && (storedBrief.organizationId !== +organizationId || storedBrief.accountId !== +accountId)) {
      // redirect brand to be under the brief's org/acc/platform path
      navigate(
        LinkCreator.createLink({
          routeKey: 'VIEW_INDIVIDUAL_BRIEF',
          variables: {
            organizationId: storedBrief.organizationId,
            accountId: storedBrief.accountId,
            briefId: storedBrief.id,
          },
        }),
      );
    }
  }, [createNew, storedBrief]);

  useEffect(() => {
    // Load in account pricing because it's used in the brief form
    if (accountId) {
      getAccountPricing(accountId);
    }
  }, [accountId]);

  return loadingAccountPricing || !pricing ? (
    <CenteredSpinnerContainer />
  ) : (
    <Suspense fallback={<LazySpinner />}>
      <Routes>
        <Route index element={<MarketerBriefsHome />} />
        {canCreateEditDraft && (
          <>
            <Route
              path="new"
              element={
                <CreateUpdateBrief
                  organizationId={organizationId}
                  accountId={accountId}
                  canPublish={canPublishEditBrief}
                  accountPricing={pricing}
                  loading={loading}
                />
              }
            />
            <Route path=":briefId/submissions/:submissionId">
              <Route index element={<BrandSubmissionPage />} />
              <Route path="checkout" element={<SubmissionCheckout />} />
              <Route path="*" element={<Navigate to="/not-found" />} />
            </Route>
          </>
        )}
        <Route path=":briefId">
          <Route index element={<IndividualBriefPage briefPagePath={briefPagePath} briefId={briefId} />} />
          {(canCreateEditDraft || canPublishEditBrief) && (
            <Route
              path="edit"
              element={
                <CreateUpdateBrief
                  currentBrief={Object.assign({}, storedBrief)}
                  organizationId={organizationId}
                  accountId={accountId}
                  isEdit
                  canPublish={canPublishEditBrief}
                  accountPricing={pricing}
                  loading={loading}
                />
              }
            />
          )}
          {canApproveCreators && (
            <>
              <Route path="checkout/confirmation" element={<CheckoutComplete />} />
              <Route path="checkout/:creatorId" element={<BriefApprovalCheckoutPage />} />
              <Route path="invitation/checkout" element={<BriefInviteApprovalCheckoutPage />} />
            </>
          )}

          {canPublishEditBrief && (
            <>
              <Route path="checkout" element={<BriefCheckoutPage />} />
              <Route path="confirmation" element={<CheckoutComplete />} />
            </>
          )}

          <Route path="*" element={<Navigate to="/not-found" />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default BrandBriefRoutes;
