import React from 'react';
import config from 'config';

import ApprovedCreatives from 'features/users/components/organisms/ApprovedCreatives';
import CreatorInfoPanel from 'features/users/components/organisms/CreatorInfoPanel';
import CreatorPortfolio from 'features/users/components/organisms/CreatorPortfolio';
import TTVideosPanel from 'features/users/components/organisms/TTVideosPanel';
import { useAvatarNotification } from 'features/users/hooks/useAvatarNotification';
import styles from 'features/users/pages/CreatorProfile/styles';
import { Box } from 'shared/components/display';

import { BrkfstTag } from 'shared/typings/tag';
import { CreatorUser } from 'shared/typings/user';

interface Props {
  isCurrentUser?: boolean;
  user: CreatorUser;
  creatorTags: BrkfstTag[];
}

const CreatorProfile: React.FC<Props> = ({ isCurrentUser = false, user, creatorTags }) => {
  useAvatarNotification();

  const showTTVideos = user.tiktokProfile || isCurrentUser;
  return (
    <Box as="article" css={styles} className="user-profile">
      <CreatorInfoPanel user={user} isCurrentUser={isCurrentUser} creatorTags={creatorTags} />
      <Box className="user-profile__right-container">
        <ApprovedCreatives userId={user.id} />
        <CreatorPortfolio user={user} isCurrentUser={isCurrentUser} />
        {config.FEATURE_FLAG_TIKTOK_INTEGRATION && showTTVideos && (
          <TTVideosPanel user={user} isCurrentUser={isCurrentUser} />
        )}
      </Box>
    </Box>
  );
};

export default CreatorProfile;
