import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';

export const BASE_CLASS = 'textarea';

export const MODIFIERS = {
  NO_MARGIN: '--no-margin',
  NO_BORDER: '--no-border',
  V_RESIZE: '--vertical-resize',
};

export default css({
  [`&.${BASE_CLASS}`]: {
    padding: '0.5rem 0.75rem',
    fontSize: '1rem',
    border: `1px solid ${COLORS.NEUTRAL300}`,
    width: '100%',
    marginTop: '1rem',
    borderRadius: '4px',
    resize: 'none',
    display: 'block',
    height: '114px',

    '&:not(:disabled):hover,&:focus': {
      cursor: 'text',
    },

    '&::placeholder': {
      color: COLORS.NEUTRAL600,
    },

    '&:disabled': {
      backgroundColor: 'white',
      color: 'initial',
    },
    [`&${MODIFIERS.NO_MARGIN}`]: {
      marginTop: 0,
      marginBottom: 0,
    },
    [`&${MODIFIERS.NO_BORDER}`]: {
      border: 'none',
    },
    [`&${MODIFIERS.V_RESIZE}`]: {
      resize: 'vertical',
    },
  },
});
