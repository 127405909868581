export enum BriefStatus {
  DRAFT = 0,
  OPEN = 1,
  CLOSED = 2,
  PENDING = 6,
  FULL = 7,
}

export enum BriefTypes {
  SYSTEM = 0,
  BRAND = 1,
}

export const briefStatusDisplay = {
  [BriefStatus.DRAFT]: 'Draft',
  [BriefStatus.OPEN]: 'Open',
  [BriefStatus.CLOSED]: 'Closed',
  [BriefStatus.PENDING]: 'Pending',
  [BriefStatus.FULL]: 'Full',
};
