import React from 'react';
import { faCheck, faPen } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';

import { Flex, Text } from 'shared/components/display';
import { splitPaneStepHeaderStyles } from 'shared/components/forms/MultiStepForm/Steps/SplitPaneStepWrapper/splitPaneStepStyles';
import Heading from 'shared/components/molecules/Heading';
import PromptButton from 'shared/components/molecules/PromptButton';

import { FORM_COPY } from 'shared/config/copy';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { TEXT_VARIANTS } from 'shared/styles/text';
interface Props {
  isActive?: boolean;
  isActiveStepDirty?: boolean;
  isCompleted?: boolean;
  onClick?: () => void;
  onKeyPress?: (event: any) => any;
  stepKey: number;
  title: string;
  titleInfo?: { header?: string; text?: string };
  isOptional?: boolean;
  isConfirmationStep?: boolean;
  isLatestStep?: boolean;
}
const StepIconAndTitle: React.FC<Props> = ({
  isActive,
  isCompleted,
  onClick,
  onKeyPress,
  stepKey,
  title,
  titleInfo,
  isLatestStep,
}) => {
  const isInteractive = isCompleted && !isActive;

  const interactiveProps = isInteractive
    ? {
        onClick,
        onKeyPress,
        tabIndex: 0,
      }
    : {};

  return (
    <Flex css={splitPaneStepHeaderStyles} {...interactiveProps}>
      <Flex
        className={cs('step-nav__icon', {
          ['step-nav__icon--active']: isActive,
          ['step-nav__icon--completed']: isCompleted,
          ['step-nav__icon--clickable']: isInteractive,
        })}
      >
        {(isCompleted && !isLatestStep) || isActive ? (
          <FontAwesomeIcon
            icon={isActive ? faPen : faCheck}
            size="xs"
            className="step-nav__edit-icon"
            role="navigation"
            aria-label={`Step ${stepKey} - ${title}`}
          />
        ) : (
          ''
        )}
      </Flex>
      <Heading
        text={title}
        headerText={titleInfo?.header}
        descriptionText={titleInfo?.text}
        variant={isActive ? TEXT_VARIANTS.SUBHEADING : TEXT_VARIANTS.BODY}
        className={cs('step-nav__title', {
          [`step-nav__title--clickable`]: isInteractive,
        })}
      />
    </Flex>
  );
};

const SplitPaneStepHeader: React.FC<Props> = ({
  isActive,
  isActiveStepDirty,
  isCompleted,
  onClick,
  onKeyPress,
  stepKey,
  title,
  titleInfo,
  isOptional,
  isConfirmationStep,
  isLatestStep,
}) => {
  const isInteractive = isCompleted && !isActive;
  if (isActiveStepDirty) {
    return (
      <Flex css={splitPaneStepHeaderStyles} className="step-nav">
        {!isConfirmationStep && (
          <span
            className={cs('step-nav__left-border', {
              ['step-nav__left-border--completed']: isInteractive,
            })}
          />
        )}
        <PromptButton
          text={FORM_COPY.MODAL_WARNING_DATA_LOSS}
          // @ts-ignore
          onConfirm={onClick}
          modalSize="small"
          confirmButtonText="Yes"
          cancelButtonText="No"
          ariaLabel="Data Loss Warning"
          variant={BUTTON_VARIANTS.TEXT_ONLY}
          className="step-nav__prompt-btn"
          disabled={!isInteractive}
        >
          <>
            <StepIconAndTitle
              isCompleted={isCompleted}
              stepKey={stepKey}
              title={title}
              isActive={isActive}
              titleInfo={titleInfo}
              isLatestStep={isLatestStep}
            />
          </>
        </PromptButton>
        {isOptional && (
          <Text as="h3" variant={TEXT_VARIANTS.CAPTION} className="step-nav__subtitle">
            Optional
          </Text>
        )}
      </Flex>
    );
  }
  return (
    <Flex css={splitPaneStepHeaderStyles} className="step-nav">
      {!isConfirmationStep && (
        <span
          className={cs('step-nav__left-border', {
            ['step-nav__left-border--completed']: isInteractive,
          })}
        />
      )}
      <StepIconAndTitle
        isCompleted={isCompleted}
        stepKey={stepKey}
        title={title}
        onClick={onClick}
        onKeyPress={onKeyPress}
        isActive={isActive}
        titleInfo={titleInfo}
        isLatestStep={isLatestStep}
      />
      {isOptional && (
        <Text as="h3" variant={TEXT_VARIANTS.CAPTION} className="step-nav__subtitle">
          Optional
        </Text>
      )}
    </Flex>
  );
};

export default SplitPaneStepHeader;
