import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Button } from 'rebass';
import { Field, Form, Formik } from 'formik';

import { Box, Flex, Text } from 'shared/components/display';
import FormButton from 'shared/components/FormButton';
import SocialMediaHandleInput from 'shared/components/SocialMediaHandleInput/SocialMediaHandleInput';
import TextInput from 'shared/components/TextInput';
import { USER_COPY } from 'shared/config/copy';
import { makeLabel } from 'shared/lib/formik';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import COLORS from 'shared/styles/colors';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { BrkfstUser } from 'shared/typings/user';

import { getPortfolioSchema } from '../../CreatorOnboardingStepsFields';

import styles from './styles';

interface Props {
  user: BrkfstUser;
  onSubmit: (values: any) => void;
}

const PORTFOLIO_FIELDNAMES = {
  PORTFOLIO_LINK: 'portfolioLink',
  TIKTOK_HANDLE: 'tiktokHandle',
  INSTAGRAM_HANDLE: 'instagramHandle',
};

const PortfolioForm: React.FC<Props> = ({ user, onSubmit }) => {
  const initialValues = {
    [PORTFOLIO_FIELDNAMES.PORTFOLIO_LINK]: user.portfolioLink || '',
    [PORTFOLIO_FIELDNAMES.TIKTOK_HANDLE]: user.socialMediaInfo?.tiktok || '',
    [PORTFOLIO_FIELDNAMES.INSTAGRAM_HANDLE]: user.socialMediaInfo?.instagram || '',
  };

  const onCancel = (setValues: (values: any) => void) => {
    setValues(initialValues);
  };

  return (
    <Box css={styles} className="portfolio-form">
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={getPortfolioSchema()}>
        {({ setValues }) => (
          <Form>
            <Text className="portfolio-form__label" variant={TEXT_VARIANTS.H6}>
              {makeLabel(PORTFOLIO_FIELDNAMES.PORTFOLIO_LINK)}
            </Text>
            <Field
              name={PORTFOLIO_FIELDNAMES.PORTFOLIO_LINK}
              component={TextInput}
              label={makeLabel(PORTFOLIO_FIELDNAMES.PORTFOLIO_LINK)}
              placeholder="Portfolio Link"
            />
            <Text className="portfolio-form__label" variant={TEXT_VARIANTS.H6}>
              Instagram
            </Text>
            <Field
              name={PORTFOLIO_FIELDNAMES.INSTAGRAM_HANDLE}
              label={makeLabel(PORTFOLIO_FIELDNAMES.INSTAGRAM_HANDLE)}
              placeholder="Instagram Handle"
              component={SocialMediaHandleInput}
            />
            <Text className="portfolio-form__label" variant={TEXT_VARIANTS.H6}>
              TikTok
            </Text>
            <Field
              name={PORTFOLIO_FIELDNAMES.TIKTOK_HANDLE}
              label={makeLabel(PORTFOLIO_FIELDNAMES.TIKTOK_HANDLE)}
              placeholder="tiktok123"
              component={SocialMediaHandleInput}
            />

            <Flex className="portfolio-form__btns-wrapper">
              <Button
                className="portfolio-form__cancel-btn"
                variant={BUTTON_VARIANTS.OPTION}
                type="button"
                onClick={() => onCancel(setValues)}
              >
                Cancel
              </Button>
              <FormButton type="submit" disableUntilTouched>
                Save Changes
              </FormButton>
            </Flex>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default PortfolioForm;
