import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';
import { faAngleRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import CenteredSpinner from 'features/ui/CenteredSpinner';
import ContactInfoForm from 'features/users/components/organisms/ContactInfoForm';
import PaymentInfoForm from 'features/users/components/organisms/PaymentInfoForm/PaymentInfoForm';
import PortfolioForm from 'features/users/components/organisms/PortfolioForm/PortfolioForm';
import { ProfileInfoSections } from 'features/users/components/organisms/ProfileInfoForm/config';
import ProfileInfoForm from 'features/users/components/organisms/ProfileInfoForm/ProfileInfoForm';
import TextNotificationsForm from 'features/users/components/organisms/TextNotificationsForm/TextNotificationsForm';
import { WorkInfoSections } from 'features/users/components/organisms/WorkInfoForm/config';
import WorkInfoForm from 'features/users/components/organisms/WorkInfoForm/WorkInfoForm';
import { useUser } from 'features/users/hooks/useUser';
import { Box, Flex, Text } from 'shared/components/display';

import axiosClient from 'shared/axiosClient';
import { API_TAG_ROUTES } from 'shared/config/routes/api/apiTagRoutes';
import { USER_TOASTS } from 'shared/config/toasts/userToasts';
import { useQuery } from 'shared/hooks/useQuery';
import { breakpoints } from 'shared/styles/styleFunctions';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { capitalizeWithoutConjunctions } from 'shared/utilities/stringUtility';

import MobileView from './MobileView/MobileView';
import { formatAccountSettingsInfo, sections } from './config';
import styles from './styles';

const CreatorAccountSettings = () => {
  const { setQuery, getQuery } = useQuery();
  const { tab } = getQuery();
  const [activeForm, setActiveForm] = useState(tab || 'Portfolio');
  const params = useParams<any>();
  const userId = +params.userId;
  const { user, updateUser, createOrUpdatePhone } = useUser(userId);
  const [standardTags, setStandardTags] = useState({});

  const isMobile = useMediaQuery({
    maxWidth: breakpoints.md - 1,
  });

  useEffect(() => {
    axiosClient
      .httpRequest<{ category: string; values: string[] }[]>({
        url: API_TAG_ROUTES.TAG_CATEGORIES,
        method: 'GET',
      })
      .then(({ data }) => {
        const tags = data.reduce((acc, val) => {
          acc[val.category] = val.values.map((value) => ({
            label: value.toUpperCase() === value ? value : capitalizeWithoutConjunctions(value),
            value,
          }));
          return acc;
        }, {});
        setStandardTags(tags);
      });
  }, []);

  const onSubmit = (values, activeSubSection?: WorkInfoSections | ProfileInfoSections | null) => {
    const payload = formatAccountSettingsInfo(values, activeForm, user.creatorTags, activeSubSection);
    updateUser({
      data: {
        id: user.id,
        ...payload,
      },
      successToast: {
        message: USER_TOASTS.UPDATED_USER_FIELD,
      },
    });
  };

  const renderForm = (formName) => {
    switch (formName) {
      case 'Portfolio':
        return <PortfolioForm user={user} onSubmit={onSubmit} />;
      case 'Contact Info':
        return <ContactInfoForm userId={userId} />;
      case 'Profile Info':
        return <ProfileInfoForm user={user} tags={standardTags} onSubmit={onSubmit} />;
      case 'Work Info':
        return <WorkInfoForm user={user} tags={standardTags} onSubmit={onSubmit} />;
      case 'Payment':
        return <PaymentInfoForm user={user} onSubmit={onSubmit} />;
      case 'Text Notifications':
        return <TextNotificationsForm user={user} onSubmit={createOrUpdatePhone} />;
      default:
        return null;
    }
  };

  const onTabClick = (tabName) => {
    setQuery({ tab: tabName });
    setActiveForm(tabName);
  };

  return Object.keys(standardTags).length > 0 ? (
    <Flex css={styles} className="creator-account-settings">
      {!isMobile ? (
        <>
          <Flex className="creator-account-settings__side-nav">
            <Text variant={TEXT_VARIANTS.H4} className="creator-account-settings__side-nav-header">
              Account Details
            </Text>
            {sections.map(({ name, icon }) => (
              <Box
                key={name}
                onClick={() => onTabClick(name)}
                dataCy="creator-account-settings__btn"
                className={classNames('creator-account-settings__btn', {
                  'creator-account-settings__btn--active': activeForm === name,
                })}
              >
                <Flex className="creator-account-settings__btn-content">
                  <FontAwesomeIcon icon={icon} className="creator-account-settings__left-icon" fixedWidth />
                  <span className="creator-account-settings__btn-name">{name}</span>
                  <FontAwesomeIcon icon={faAngleRight} className="creator-account-settings__right-icon" fixedWidth />
                </Flex>
              </Box>
            ))}
          </Flex>
          <Box className="creator-account-settings__form-container">
            <Text variant={TEXT_VARIANTS.H4} className="creator-account-settings__form-header">
              {activeForm}
            </Text>
            {renderForm(activeForm)}
          </Box>
        </>
      ) : (
        // Mobile View
        <MobileView
          sections={sections}
          activeForm={activeForm}
          onTabClick={onTabClick}
          user={user}
          userId={userId}
          onSubmit={onSubmit}
          tags={standardTags}
          createOrUpdatePhone={createOrUpdatePhone}
        />
      )}
    </Flex>
  ) : (
    <CenteredSpinner />
  );
};

export default CreatorAccountSettings;
