import config from 'config';
import { tldExists } from 'tldjs';
import * as yup from 'yup';

import { CONSTRAINT_LIMITS, CONSTRAINTS, MESSAGES } from 'shared/config/validations';
import {
  hasHttpPrefix,
  hasSpecialCharacters,
  isValidDomain,
  removeExtensionFromFilename,
} from 'shared/utilities/stringUtility';
import { getFileMimeType, hasValidMimeTypeOrExtension } from 'shared/utilities/validator/utils';

// NOTE: If you add methods, also add them to src/shared/typings/yup.d.ts

yup.addMethod(yup.string, 'money', function ({ max = Infinity, min = CONSTRAINT_LIMITS.DEFAULT_MONEY_MIN, message }) {
  return this.test({
    name: 'no-dollar-sign-number',
    test(value) {
      const noDollarSign = CONSTRAINTS.NO_DOLLAR_SIGN_CONSTRAINT.test(value);
      const noWhiteSpace = CONSTRAINTS.NO_SPACES.test(value);
      if (!noDollarSign) {
        return this.createError({
          message: MESSAGES.NO_DOLLAR_SIGN,
        });
      }
      if (!noWhiteSpace) {
        return this.createError({
          message: MESSAGES.WHITESPACE,
        });
      }
      const number = +value;
      if (!number && number !== 0) {
        return this.createError({
          message: MESSAGES.NUMBER,
        });
      }
      if (number < min || number > max) {
        return this.createError({
          message,
        });
      }
      return true;
    },
  });
});

yup.addMethod(
  yup.string,
  'domain',
  function (args: { checkSubdomain?: boolean; checkSlash?: boolean } = { checkSubdomain: true, checkSlash: true }) {
    return this.test({
      name: 'domain-validation',
      message: MESSAGES.INVALID_DOMAIN,
      test(domain: string): boolean | yup.ValidationError {
        const { checkSubdomain, checkSlash } = args;
        if (domain) {
          if (hasSpecialCharacters(domain)) {
            return false;
          }
          if (!hasHttpPrefix(domain)) {
            return this.createError({
              message: MESSAGES.DOMAIN,
            });
          }
          if (checkSubdomain && domain.includes('www.')) {
            return this.createError({
              message: MESSAGES.INVALID_SUBDOMAIN,
            });
          }
          if (checkSlash && domain.endsWith('/')) {
            return this.createError({
              message: MESSAGES.ENDS_IN_SLASH,
            });
          }
          if (domain.endsWith('.')) {
            return this.createError({
              message: MESSAGES.DOMAIN_ENDS_IN_PERIOD,
            });
          }
          if (!tldExists(domain)) {
            return this.createError({
              message: MESSAGES.TLD_ERROR,
            });
          }
        }
        return true;
      },
    });
  },
);

yup.addMethod(yup.string, 'displayUrl', function ({ message = MESSAGES.DOMAIN } = {}) {
  return this.test({
    message,
    name: 'url-validation',
    test(domain) {
      if (domain) {
        return isValidDomain(domain) && hasHttpPrefix(domain);
      }
      return true;
    },
  });
});

yup.addMethod(yup.object, 'supportedFile', function ({ supportedFormats, message }) {
  return this.test({
    name: 'supported-file',
    message,
    test(value) {
      const { file } = value as { file: File };
      if (!file?.type) {
        // throw an error if a file is uploaded (the file has a size) and their media type is undefined
        return !file?.size;
      }
      const fileType = getFileMimeType(file);
      return hasValidMimeTypeOrExtension(fileType, file.name, supportedFormats);
    },
  });
});

yup.addMethod(yup.object, 'fileSize', function ({ fileSizeMax, message }) {
  return this.test({
    name: 'file-size-limit',
    message,
    test(value) {
      if (!value?.file?.type) return true;
      return value.file?.size < fileSizeMax;
    },
  });
});

yup.addMethod(yup.object, 'fileNameLength', function ({ message }) {
  return this.test({
    name: 'file-name-length',
    message,
    test(value) {
      const filename = removeExtensionFromFilename(value?.file?.name);
      if (filename.length > config.FILE_NAME_LENGTH) {
        return this.createError({
          message: MESSAGES.FILE_NAME_LENGTH,
        });
      }
      return true;
    },
  });
});

// When only the name is passed
yup.addMethod(yup.string, 'filename', function () {
  return this.test({
    name: 'no-special-char',
    message: MESSAGES.FILENAME,
    test(value) {
      const filename = removeExtensionFromFilename(value);
      /** ignoring the extension, this allows `a-z, A-Z, 0-9, -, _ & SPACE`.*/
      const isInvalid = CONSTRAINTS.SPECIAL_CHARS_WHITESPACE.test(filename) || filename.trim().length === 0;
      return !isInvalid;
    },
  });
});

// When the name needs to be read from the file object
yup.addMethod(yup.object, 'fileNameFormat', function () {
  return this.test({
    name: 'no-special-char',
    test(value) {
      if (!value?.file?.name) return true;
      const filename = removeExtensionFromFilename(value?.file?.name);
      /** ignoring the extension, this allows `a-z, A-Z, 0-9, -, _ & SPACE`.*/
      const isInvalid = CONSTRAINTS.SPECIAL_CHARS_WHITESPACE.test(filename) || filename.trim().length === 0;
      if (isInvalid) {
        return this.createError({
          message: MESSAGES.FILENAME,
        });
      }
      return true;
    },
  });
});

yup.addMethod(yup.string, 'customRequiredString', function (message = MESSAGES.REQUIRED_FIELD) {
  return this.test({
    name: 'custom-required-string',
    message,
    test(value) {
      if (value === undefined) return false;
      const isInvalid = value.trim().length === 0;
      return !isInvalid;
    },
  });
});

yup.addMethod(yup.string, 'tiktokHandle', function () {
  return this.test({
    name: 'tiktok-handle',
    test(value: string): boolean | yup.ValidationError {
      if (!value) return true;
      const endsInAPeriod = /\.$/;
      if (endsInAPeriod.test(value))
        return this.createError({
          message: MESSAGES.ENDS_IN_PERIOD,
        });
      const onlyNumbers = /^\d+$/;
      if (onlyNumbers.test(value))
        return this.createError({
          message: MESSAGES.ONLY_NUMBERS,
        });
      if (value.length < 2)
        return this.createError({
          message: MESSAGES.LESS_THAN_TWO_CHARACTERS,
        });
      if (value.length > 24)
        return this.createError({
          message: MESSAGES.TIKTOK_TOO_MANY_CHARACTERS,
        });
      const invalidCharacters = /[^a-zA-Z0-9_\.]/;
      if (invalidCharacters.test(value))
        return this.createError({
          message: MESSAGES.INVALID_CHARACTERS,
        });
      return true;
    },
  });
});

yup.addMethod(yup.string, 'instagramHandle', function () {
  return this.test({
    name: 'instagram-handle',
    test(value: string): boolean | yup.ValidationError {
      if (!value) return true;
      // cannot start or end with a period, nor have two periods in a row
      const illegalPeriods = /^\.|\.{2}|\.$/;
      if (illegalPeriods.test(value))
        return this.createError({
          message: "Username can't start or end with a period or contain consecutive periods",
        });
      const onlyNumbers = /^\d+$/;
      if (onlyNumbers.test(value))
        return this.createError({
          message: MESSAGES.ONLY_NUMBERS,
        });
      if (value.length > 30)
        return this.createError({
          message: "Username can't exceed 30 characters",
        });
      const invalidCharacters = /[^a-zA-Z0-9_\.]/;
      if (invalidCharacters.test(value))
        return this.createError({
          message: MESSAGES.INVALID_CHARACTERS,
        });
      return true;
    },
  });
});

yup.addMethod(yup.string, 'allowedPortfolioDomain', function () {
  return this.test({
    name: 'url-validation',
    message: 'Please enter a non-IG or non-TikTok link',
    test(value): boolean | yup.ValidationError {
      if (!value) return true;
      const notAllowedDomains = ['instagram.com', 'tiktok.com'];
      const domainRegExp = new RegExp(`${notAllowedDomains.join('|')}`);
      return !domainRegExp.test(value);
    },
  });
});

yup.addMethod(yup.string, 'onlyLettersAndSpaces', function () {
  return this.test({
    name: 'only-letters-and-spaces',
    message: 'Valid characters are A-Z a-z',
    test(value) {
      const pattern = /[^a-z A-Z]/;
      return !pattern.test(value);
    },
  });
});

export const useYupValidation = (schema?: any) => {
  return schema ? yup.object(schema).shape(schema) : null;
};
