/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

import { hasBeenSeenToggled } from 'features/submissions/submissions.slice';

import { Brief } from 'shared/typings/briefs';
import { BriefStatus } from 'shared/typings/briefs/enums';
import { BrkfstFile } from 'shared/typings/file';

interface BriefState {
  data: Brief[];
  total: number;
  refresh?: boolean;
}

interface BriefPayload<T = BriefState> {
  data: T;
}

const initialState = {
  data: [],
  total: 0,
  refresh: false,
};

const briefsSlice = createSlice<BriefState, SliceCaseReducers<BriefState>, 'briefs'>({
  name: 'briefs',
  initialState,
  reducers: {
    briefsLoaded: (briefs, action: PayloadAction<BriefPayload>) => {
      const { data, total } = action.payload.data;
      briefs.data = data;
      briefs.total = total;
      briefs.refresh = false;
    },
    briefAdded: (briefs, action: PayloadAction<BriefPayload<Brief>>) => {
      const brief = action.payload.data;
      // add to the beginning to maintain created by order
      briefs.data.unshift(brief);
      briefs.total += 1;
      briefs.refresh = briefs.data.length !== briefs.total;
    },
    briefChanged: (briefs, action: PayloadAction<BriefPayload<Brief>>) => {
      briefs.data = briefs.data.map((brief) => {
        if (brief.id === action.payload.data.id) {
          return {
            ...brief,
            ...action.payload.data,
          };
        }
        return brief;
      });
    },
    briefRemoved: (briefs, action: PayloadAction<BriefPayload<Brief>>) => {
      briefs.data = briefs.data.filter((brief) => brief.id !== action.payload.data.id);
      briefs.total -= 1;
      briefs.refresh = briefs.data.length !== briefs.total;
    },
    briefRefreshed: (briefs) => {
      briefs.refresh = true;
    },
    briefAssetAdded: (briefs, action: PayloadAction<BrkfstFile & { briefId: number }>) => {
      const { briefId, ...file } = action.payload;
      briefs.data = briefs.data.map((brief) => {
        if (brief.id === briefId) {
          return {
            ...brief,
            brandAssets: [...brief.brandAssets, file],
          };
        }
        return brief;
      });
    },
    briefAssetUpdated: (
      briefs,
      action: PayloadAction<{ fileId: number; thumbnailUrl: string; thumbnailOffset: string }>,
    ) => {
      const { fileId, thumbnailUrl, thumbnailOffset } = action.payload;
      briefs.data = briefs.data.map((brief) => {
        const matchedFile = brief.brandAssets.find(({ id }) => id === fileId);
        if (matchedFile) {
          return {
            ...brief,
            brandAssets: brief.brandAssets.map((asset) => {
              if (asset.id === fileId)
                return {
                  ...asset,
                  thumbnailUrl,
                  thumbnailOffset,
                };
              return asset;
            }),
          };
        }
        return brief;
      });
    },
  },
  extraReducers: (builder) =>
    builder.addCase(hasBeenSeenToggled, (briefs, action) => {
      const { hasBeenSeen, briefId } = action.payload.data;
      const brief = briefs.data.find((br) => br.id === briefId);
      if (brief) {
        brief.numNewSubmissions = hasBeenSeen ? brief.numNewSubmissions - 1 : brief.numNewSubmissions + 1;
      }
    }),
});

export const {
  briefsLoaded,
  briefAdded,
  briefChanged,
  briefRemoved,
  briefAssetAdded,
  briefAssetUpdated,
  briefRefreshed,
} = briefsSlice.actions;

export default briefsSlice.reducer;

interface StoreState {
  briefs: BriefState;
}

export const getBrief = (id: number) => (state: StoreState) => {
  return state.briefs.data.find((element) => element.id === id);
};

export const getBriefs = (state: StoreState) => state.briefs.data;

export const getClosedBriefs = ({ briefs: { data } }: StoreState) =>
  data.filter(({ status }) => status === BriefStatus.CLOSED);

export const getOpenBriefs = ({ briefs: { data } }: StoreState) =>
  data.filter(({ status }) => status === BriefStatus.OPEN);

export const getBriefCount = (state: StoreState) => state.briefs.total;

export const getShouldRefreshBriefs = (state: StoreState) => state.briefs.refresh;
