import React from 'react';
import { cx } from '@emotion/css';
import { faX } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button, Flex } from 'shared/components/display';
import PromptButton from 'shared/components/molecules/PromptButton';

import { BUTTON_VARIANTS } from 'shared/styles/button';

import styles from './styles';

interface Props {
  children: string;
  className?: string;
  onDelete?: () => void;
  theme?: 'default' | 'compact' | 'outline';
  color?: string;
  onClick?: () => void;
  promptText?: string;
  promptTitle?: string;
  css?: any[]; //any styles to override the default styles
}
const Tag = React.forwardRef<any, Props>(
  ({ children, className, onDelete, theme = 'default', color, onClick, promptText, promptTitle, css = [] }, ref) => {
    const isDeletable = Boolean(onDelete);

    const handleDelete = (e) => {
      e.stopPropagation();
      onDelete && onDelete();
    };

    return (
      <Flex css={[styles(color), ...css]} className={cx('tag', `tag--${theme}`, className)} onClick={onClick} ref={ref}>
        {children}
        {isDeletable &&
          (promptText ? (
            <PromptButton
              onConfirm={handleDelete}
              variant={BUTTON_VARIANTS.TEXT_ONLY}
              text={promptText}
              title={promptTitle}
              className="tag__remove-btn"
            >
              <RemoveIcon className="tag__remove-icon" />
            </PromptButton>
          ) : (
            <Button type="button" variant={BUTTON_VARIANTS.TEXT_ONLY} onClick={onDelete} className="tag__remove-btn">
              <RemoveIcon className="tag__remove-icon" />
            </Button>
          ))}
      </Flex>
    );
  },
);
export default React.memo(Tag);

interface RemoveIconProps {
  className?: string;
}

const RemoveIcon: React.FC<RemoveIconProps> = ({ className }) => {
  return (
    <FontAwesomeIcon
      aria-label="remove tag"
      role="button"
      title="remove tag"
      className={className}
      icon={faX}
      size="xs"
    />
  );
};
