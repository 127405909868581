export const BRIEF_TOASTS = {
  APPLIED_BRAND: 'Successfully applied to the brand',
  APPLIED_BRIEF: 'Successfully applied to brief',
  BRIEF_CLOSED: 'Brief {name} closed',
  PUBLISHED: 'Brief {name} successfully published',
  REPUBLISHED: 'Brief {name} successfully republished',
  BRIEF_SAVED: 'Brief {name} successfully saved',
  NO_NAME_SAVED: 'Unnamed brief successfully saved',
  BRIEF_CREATED: 'Brief {name} successfully created',
  BRIEF_REOPENED: 'Brief {name} successfully reopened',
  BRIEF_PAUSED: 'Brief {name} successfully paused',
  BRIEF_RESUMED: 'Brief {name} successfully resumed',
  NOT_EDITABLE_BRIEF: 'You are no longer able to edit once your submission has been approved',
  CREATOR_INVITED: 'Creator successfully invited.',
  CREATORS_INVITED: 'Creators successfully invited.',
  BRIEF_DELETED: 'Brief successfully deleted',
  CREATOR_INVITED_CLOSED:
    'Thank you for accepting our invite, but this brief is already closed. Please apply to another brief!',
  CREATOR_INVITED_FULL:
    'Thank you for accepting our invite, but all creator spots for this brief have been filled. Make sure to apply to any other open briefs!',
};
