import { css } from '@emotion/react';
import theme from 'shared/styles/themes';

export const briefDurationStyles = css({
  '&.brief-duration': {
    maxWidth: '800px',
  },
  '.brief-duration': {
    '&__wrapper': {
      marginTop: theme.space[4],
    },
    '&__header': {
      marginTop: theme.space[4],
    },
  },
});
